import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { Chart, Bar, Polar, Pie } from 'react-chartjs-2';
import { useAuth } from '../utils/auth';
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/eu'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { setDate } from 'date-fns';
import DateSelector from '../components/DateSelector';
import WeekPicker from '../pickers/WeekPicker';
const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    errorField: {
        color: 'red'
    },
    alertField: {
        color: 'orange'
}
}));

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

export default function Reto(props) {
    let { device, route, minLimit } = props;
    const { tecnalia_client } = useAuth();
    const [options, setOptions] = useState({})
    const [data, setData] = useState([])
    const [selectedDate, handleDateChange] = useState(new Date());
    const { t, i18n } = useTranslation(['reto', 'date_selector']);
    const classes = useStyles();
    const width = useWidth();
    const isSmallScreen = /xs|sm/.test(width);
    const [isRequesting, setRequesting] = useState(false)
    const [hasErrors, setErrors] = useState(false)
    const [currentError, setCurrentError] = useState([])
    const [initDate, setInitDate] = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(moment(new Date()));

    if (isSmallScreen) {
        options['maintainAspectRatio'] = false;
    } else {
        options['maintainAspectRatio'] = false;
        options['responsive'] = true;
    }

    const handleDateStartChange = (value) => {
        console.log('handleDateStartChange', value)
        handleDateChange(value)
        setInitDate(value.start)
        // setEndDate(moment(new Date(value.format("YYYY-MM-") + value.daysInMonth())))


    }
    const processRequest = async function (device, startdate) {
        // if (moment(startdate) < moment(minLimit)) startdate = minLimit;
        startdate = moment(startdate).format('YYYY-MM-DD')
        // enddate = moment(enddate).format('YYYY-MM-DD')
        return await tecnalia_client(route, { device, startdate}).then((response) => {
            console.log(response)
            if ('error' in response) {
                setCurrentError(response['error'])
                throw response
            }
            setData(response)
            // response['options']['scales']['yAxes'][0]['ticks']['callback'] = function (value, index, values) {
            //     if (index === values.length - 1) return Math.min(Math.min.apply(this, response['graph']['datasets'][0]['data']), Math.min.apply(this, response['graph']['datasets'][0]['data']))
            //     else if (index === 0) return Math.max(Math.max.apply(this, response['graph']['datasets'][0]['data']), Math.max.apply(this, response['graph']['datasets'][0]['data']))
            //     else return ''
            //}
            setRequesting(false)

        }).catch((error) => {
            console.error(error);

            setErrors(true)
            setRequesting(false)
        });
    }
    const handleClick = () => {
        setErrors(false)
        setRequesting(true)
        setCurrentError([])
        processRequest(device, initDate)
    }

    let agregado_options = options

    agregado_options['legend'] = {
        display: true,
        labels: {
            boxWidth: 18,
            fontSize: 12,
            generateLabels: function (chart) {
                var labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                labels = labels.map((label) => {
                    if (Array.isArray(label.text)) {
                        label.text = t(label.text[0], label.text[1])
                    } else {
                        label.text = t(label.text)//moment(label.text).format('YYYY-MM-DD')//t(label.text)
                    }
                    return label
                })

                return labels;
            }
        }
    }
    agregado_options['tooltips'] = {
        callbacks: {
            label: function (ttitem, data) {
                var label = ''
                if (Array.isArray(data.datasets[ttitem.datasetIndex].label)) {
                    label = t(data.datasets[ttitem.datasetIndex].label[0], data.datasets[ttitem.datasetIndex].label[1])
                } else {
                    label = t(data.datasets[ttitem.datasetIndex].label) || '';
                }

                if (label) {
                    label += ': ';
                }
                label += data.datasets[ttitem.datasetIndex].data[ttitem.index] + 'mins'
                return label;
            }
        }
    }
    agregado_options['title'] = {

        display: true,
        text: t('reto_semanal_4h')
    }
    return <Grid spacing={0} container>
        <Grid item xs={12}><Typography>{t('service_description')}</Typography></Grid>
        <Grid item container xs={12}>
            <Grid item xs={8}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                    <WeekPicker value={initDate} minDate={minLimit} disableFuture={true} tr={t} onChange={handleDateStartChange} />
                    {/* <DatePicker
                        views={["year", "month"]}
                        label={t("select_month")}
                        minDate={minLimit}
                        maxDate={new Date()}
                        value={selectedDate}
                        disableFuture={true}
                        onChange={handleDateStartChange}
                    /> */}
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
                <Grid container item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleClick} disabled={isRequesting}>{t('submit')}</Button>
                </Grid>
            </Grid>


        </Grid>
        <Grid item container xs={12}>
            {isRequesting && <Grid item container xs={12} justify='center'><Typography><Box fontWeight='bold'>{t('waiting_response')}</Box></Typography></Grid>}
            {hasErrors && <Grid item container xs={12} justify='center'><Typography className={classes.errorField}><Box fontWeight='bold'>{t(currentError[0])} [{currentError[1]}]: {t(currentError[2])}</Box></Typography></Grid>}
            {!hasErrors && !isRequesting && <Grid item container xs={12}>
                {data.map((value) => {
                    return <>
                        <Grid item xs={12}>{t("challenge_for_week", {value:moment(initDate).format('DD/MM/YYYY')})}</Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}>
                                    <Bar data={value.graph} options={agregado_options} />
                                </Box>
                            </Grid>
                        {value.alerts.length === 0 && <Grid container item xs={12}><Typography>{t(value.text[0],value.text[1])}</Typography></Grid> }
                        {value.alerts.length !== 0 && value.alerts.map((alert)=>{ return <Grid container item xs={12}><Typography className={classes.alertField}>{t(alert[0], {'value':moment(alert[1]).format('L')})}</Typography></Grid> })}
                        

                        </Grid>
                    </>
                })}


            </Grid>}
        </Grid>
    </Grid>
}