import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import RangedGrapics from '../components/RangedGraphics'
import DailyConsumption from '../components/DailyConsumption'
import {useTranslation} from 'react-i18next'
import moment from 'moment';
import { getStartContractDate, useAuth } from '../utils/auth';
const useStyles = makeStyles({
  mycurrentconsumption: {
      background: 'white',
      borderRadius: '1rem'
  },
  vspacer: {
    height:'6rem'
  }
});

export default function Consumo({value}){
    const classes = useStyles();
    const { t } = useTranslation('consumption');

    const minLimit = getStartContractDate(useAuth());
    return <Grid spacing={3} container>
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('last_months')}</Typography>
                <RangedGrapics minLimit={minLimit} device={value} labels='KW' selectable={true} dateStart={'2018-02'} route='readings/thermenergy'/>
            </Grid>
            <Grid item xs={12} className={classes.vspacer}></Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('last_30_days')}</Typography>
                <RangedGrapics 
                    minLimit={minLimit}
                    resolution='D'
                    device={value}
                    selectable={false}
                    route='readings/thermenergy'
                    dateEnd={moment().format('YYYY-MM-DD')}
                    dateStart={ moment().subtract(30, 'days').format('YYYY-MM-DD')}
                />
            </Grid>
            <Grid item xs={12} className={classes.vspacer}></Grid>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('daily_consumption')}</Typography>
                <DailyConsumption value={value} />
            </Grid>
        </Grid>
    </Grid>
}