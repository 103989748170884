import React from 'react'
//import {AuthContext} from './context'
import { makeStyles } from '@material-ui/core/styles';


function useSafeDispatch(dispatch) {
  const mounted = React.useRef(false)
  React.useLayoutEffect(() => {
    mounted.current = true
    return () => (mounted.current = false)
  }, [])
  return React.useCallback(
    (...args) => (mounted.current ? dispatch(...args) : void 0),
    [dispatch],
  )
}

// Example usage:
// const {data, error, status, run} = useAsync()
// React.useEffect(() => {
//   run(fetchPokemon(pokemonName))
// }, [pokemonName, run])
const defaultInitialState = { status: 'idle', data: {}, error: null }
function useAsync(initialState) {
  const initialStateRef = React.useRef({
    ...defaultInitialState,
    ...initialState,
  })
  const [{ status, data, error }, setState] = React.useReducer(
    (s, a) => ({ ...s, ...a }),
    initialStateRef.current,
  )

  const safeSetState = useSafeDispatch(setState)

  const setData = React.useCallback(
    data => safeSetState({ data, status: 'resolved' }),
    [safeSetState],
  )
  const setError = React.useCallback(
    error => safeSetState({ error, status: 'rejected' }),
    [safeSetState],
  )
  const reset = React.useCallback(() => safeSetState(initialStateRef.current), [
    safeSetState,
  ])

  const run = React.useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise. Maybe a function that's passed isn't returning anything?`,
        )
      }
      safeSetState({ status: 'pending' })
      return promise.then(
        data => {
          setData(data)
          return data
        },
        error => {
          setError(error)
          return Promise.reject(error)
        },
      )
    },
    [safeSetState, setData, setError],
  )

  return {
    // using the same names that react-query uses for convenience
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

export { useAsync }

export const useStyles = makeStyles({
  ghostFooter: {
    //height: 60,
    position: 'relative',
    bottom: 0,
    left: 0,
    width: '100%'
  },

  lowerBadge: {
    backgroundColor: '#4b619c',
    color: 'white',
    '& p' : {
      fontSize: '0.6rem',
      lineHeight: '0.8rem'
    }
  },

  footer: {
    background: 'white',
    border: 0,
    color: 'black',
    //height: 60,
    padding: '0.4rem 0 0 0',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  },

  header: {
    background: 'rgb( 228, 245, 251)',
    position: 'relative',
    top: 0,
    flexGrow: 1
  },

  adjust_image: {
    marginLeft: '1em',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'block'
  },
});