import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { Bar, Polar, Pie } from 'react-chartjs-2';
import { useAuth } from '../utils/auth';
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/eu'
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { setDate } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    errorField: {
        color: 'red'
    },
    infoField: {
        color: 'black'
    }
}));

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

export default function Forecast(props) {
    let { device, route, minLimit } = props;
    const { tecnalia_client } = useAuth();
    const [options, setOptions] = useState({})
    const [data, setData] = useState({})
    const [selectedDate, handleDateChange] = useState(new Date());
    const { t, i18n } = useTranslation('forecast');
    const classes = useStyles();
    const width = useWidth();
    const [currentError, setCurrentError] = useState([])
    const [isRequesting, setRequesting] = useState(false)
    const [hasErrors, setErrors] = useState(false)
    const isSmallScreen = /xs|sm/.test(width);

    const [date, setDate] = useState(moment(new Date()));//.subtract(1, 'month')

    if (isSmallScreen) {
        options['maintainAspectRatio'] = false;
    } else {
        options['maintainAspectRatio'] = false;
        options['responsive'] = true;
    }

    const processRequest = async function (device, date) {
        // if (moment(startdate) < moment(minLimit)) startdate = minLimit;
        // startdate = moment(startdate).format('YYYY-MM-DD')
        date = moment(date).format('YYYY-MM-DD')
        return await tecnalia_client(route, { device, date }).then((response) => {
            console.log(response)
            if ('error' in response) {
                setCurrentError(response['error'])
                throw response
            }
            setData(response)
            // response['options']['scales']['yAxes'][0]['ticks']['callback'] = function (value, index, values) {
            //     if (index === values.length - 1) return Math.min(Math.min.apply(this, response['graph']['datasets'][0]['data']), Math.min.apply(this, response['graph']['datasets'][0]['data']))
            //     else if (index === 0) return Math.max(Math.max.apply(this, response['graph']['datasets'][0]['data']), Math.max.apply(this, response['graph']['datasets'][0]['data']))
            //     else return ''
            //}

            setRequesting(false)

        }).catch((error) => {
            console.error(error);

            setErrors(true)
            setRequesting(false)
        });
    }
    const handleClick = () => {
        setErrors(false)
        setRequesting(true)
        setCurrentError([])
        processRequest(device, date)
    }
    const polaroptions = {
        legend: {
            display: false,
            labels: {
                boxWidth: 18,
                fontSize: 12
            }
        }
    }
    return <Grid spacing={0} container>
        {/* <Grid item xs={12}><Typography>Seleccione una fecha</Typography></Grid> */}
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography>{t('check_forecast')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleClick}>{t('submit')}</Button>
                </Grid>
            </Grid>


        </Grid>
        <Grid item container xs={12}>

            {isRequesting && <Grid item container xs={12} justify='center'><Typography><Box fontWeight='bold'>{t('waiting_response')}</Box></Typography></Grid>}
            {hasErrors && <Grid item container xs={12} justify='center'>{String(currentError[1]) !== '200' ? <Typography className={classes.errorField}><Box fontWeight='bold'>{t(currentError[0])} [{currentError[1]}]: {t(currentError[2])}</Box></Typography> : <Typography className={classes.infoField}><Box fontWeight='bold'>{t(currentError[0])}: {t(currentError[2])}</Box></Typography>}</Grid>}
            {!hasErrors && !isRequesting && <Grid item container xs={12}>
                {Object.keys(data).map((key) => {
                    return <>
                        <Grid item xs={12}>{t(key)}</Grid>
                        <Grid container item xs={12}>
                            
                            <Grid item xs={8}>
                                <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}>
                                    <Bar data={data[key]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                })}


            </Grid>}
        </Grid>
    </Grid>
}