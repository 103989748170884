import React from 'react'
import { Typography, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import RangedGrapics from '../components/RangedGraphics';
import { getStartContractDate, useAuth } from '../utils/auth';
import Tab from '@material-ui/core/Tab';
import DHTabs from '../components/DHTabs';

import {useTranslation} from 'react-i18next'
import MixedGraphics from '../components/MixedGraphics';
import Benchmark from './Benchmark';
import Forecast from './Forecast';
import Reto from './Reto';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
function generatePanels({ value, user }) {
  const minLimit = getStartContractDate({ user });
  return [
    <MixedGraphics route='consumption' device={value} minLimit={minLimit} />,
    <Benchmark route='benchmark' device={value} minLimit={minLimit}/>,
    <Forecast route='forecast' device={value} minLimit={minLimit} />,
    <Reto route='reto' device={value} minLimit={minLimit} />
  ]
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function DHSection({value}){
  const classes = useStyles();
  const { t, i18n } = useTranslation('other');
  const { user } = useAuth();

  console.log('DHSection creating tabs for value', value)
    return <Grid container direction='column'>
        <Grid item container xs={12}>
            <Grid item xs={12}>
            <DHTabs panels={generatePanels({ value, user})} active={0}>
              <Tab label={t("consumo")} {...a11yProps(1)} />
              <Tab label={t("benchmark")} {...a11yProps(2)} />
              <Tab label={t("prediccion")} {...a11yProps(3)} disabled={user.access_policy.locations.find((item)=>{return item.name.toLowerCase().indexOf('subcentral')!==-1})===undefined} />
              <Tab label={t("reto")} {...a11yProps(4)} />
            </DHTabs>
            </Grid>
            
        </Grid>
    </Grid>
}