import React from 'react'
import moment from 'moment';
import { Typography, Grid, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import FraTabs from '../components/FraTabs';
import RangedGrapics from '../components/RangedGraphics';
import FraDownload from './FraDownload';
import {useTranslation} from 'react-i18next'
import { getStartContractDate,useAuth } from '../utils/auth';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}
function generatePanels({value,user,t}){
  const minLimit = getStartContractDate({user});
  return [<><RangedGrapics minLimit={minLimit} selectable={false} labels='€' device={value} dateStart={moment().subtract(13,'months').format('YYYY-MM')}/></>,
    <><RangedGrapics minLimit={minLimit} labels='€' device={value} dateStart={moment().subtract(13,'months').format('YYYY-MM')}/></>]
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Facturation({value}){
  const classes = useStyles();
  const { t, i18n } = useTranslation('facturation');
  const { user } = useAuth();
  const minLimit = getStartContractDate({user});
  return <Grid container direction='column' xs={12}>
        <Grid item container xs={12} >
            {/* <Grid item xs={12}>
                <Typography variant='h4'>{t('my_fras')}</Typography>
            </Grid> */}
            <Grid item xs={12}>
              {/* <FraTabs panels={generatePanels({ value, user,t})} active={0}>
                <Tab label={t("monthly")} {...a11yProps(1)} />
                <Tab label={t("advanced")} {...a11yProps(2)} />
              </FraTabs> */}
              <RangedGrapics minLimit={minLimit} selectable={false} labels='€' device={value} dateStart={moment().subtract(13,'months').format('YYYY-MM')} route='cost/ranged'/>
            </Grid>

        </Grid>
        <FraDownload device={value} route={'bills'}/>

    </Grid>
}
