import React from 'react'
import {Header} from '../components/header'
import {Content} from '../components/content'
import {Footer} from '../components/footer'
import { Grid } from '@material-ui/core'

export function UserPage() {
  const [value, setValue] = React.useState("");
  const [location, setLocation] = React.useState("");
  const handleLocationChange = function (newValue) {
    //console.log(newValue);
    let selectedDevice = newValue.devices.find((dev) => { return dev.type === 'THERMAL' });
    //console.log(selectedDevice)
    setValue(selectedDevice.id);
    setLocation(newValue.id);
  }

    return (
      <>
        <Grid container >
            <Grid item xs={12} container><Header onChange={handleLocationChange}/></Grid>
            <Grid item xs={12} container style={{padding: '0 0 4em 0'}}><Content value={value} location={location}/></Grid>
            <Grid item xs={12} container><Footer /></Grid>
        </Grid>
      </>
    )
  }