import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { ImportantDevices } from '@material-ui/icons';
import { useAuth, getStartContractDate } from '../utils/auth';
import moment from 'moment';
import RangePicker from '../components/RangePicker';
import RangedGrapics from '../components/RangedGraphics';
import DailyConsumption from '../components/DailyConsumption';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useCSS = makeStyles((theme) => ({
  responsiveText: {
    fontSize: 'calc(0.6em + 0.4vw)'
  },
  styledTab: {
    background: theme.palette.primary.main,
    color: 'white',
    fontSize: 'calc(0.6em + 0.4vw)'
  },
  fullwidth: {
    width: "100% !important"
  },
  rounded: {
    "& button:first-child": {
      borderRadius: '4px 0 0 4px'
    },
    "& button:last-child": {
      borderRadius: '0 4px 4px 0'
    }
  },
  vspacer: {
    height:'2rem'
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


//export default function SubTabbed({deviceid, param, units}) {
  export default function SubTabbed(props) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const {user} = useAuth();
  //const [apiRoute, setAPIRoute] = React.useState('readings/' + param);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let {deviceid, param, units} = props;
  const [apiRoute, setAPIRoute] = React.useState('readings/' + param);
  const [device, setDevice] = React.useState(deviceid);
  useEffect(() => {
    setAPIRoute('readings/' + param);
    setDevice(deviceid);
  }, [deviceid, param, units]);

  const handleClick = (dateblock, resolution, initDate, endDate) => {
    // setErrors(false)
    // setRequesting(true)
    // setCurrentError([])
    // processRequest(value, initDate, endDate, resolution)
  }

  const minLimit = getStartContractDate(useAuth());

  //console.log("Inicio de contrato: ", minLimit);

  const styles = useCSS();

  return (
    <div>
      {/* <Paper className={styles.fullwidth}> */}
      <Paper>
        <Tabs
          key='my_current'
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="subseleccion"
          centered
          className={styles.rounded}
        >
          <Tab label={t("tab_hourly")} className={styles.styledTab} {...a11yProps(0)} />
          <Tab label={t("tab_daily")} className={styles.styledTab} {...a11yProps(1)} />
          <Tab label={t("tab_monthly")} className={styles.styledTab} {...a11yProps(2)} />
          <Tab label={t("tab_advanced")} className={styles.styledTab} {...a11yProps(3)} />
        </Tabs>
      </Paper>

        <TabPanel value={value} index={0}>
          <RangedGrapics 
            minLimit={minLimit}
            resolution='H'
            device={device}
            selectable={false}
            labels={units}
            route={apiRoute}
            dateEnd={moment().format('YYYY-MM-DD HH:mm')}
            dateStart={ moment().subtract(48, 'hours').format('YYYY-MM-DD HH:mm')}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RangedGrapics 
              minLimit={minLimit}
              resolution='D'
              device={device}
              selectable={false}
              labels={units}
              route={apiRoute}
              dateEnd={moment().format('YYYY-MM-DD')}
              dateStart={ moment().subtract(30, 'days').format('YYYY-MM-DD')}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RangedGrapics
            minLimit={minLimit}
            resolution='M'
            device={device}
            selectable={false}
            labels={units}
            route={apiRoute}
            dateEnd={moment().format('YYYY-MM-DD')}
            dateStart={ moment().subtract(13, 'months').format('YYYY-MM-DD')}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RangePicker userDataStart={minLimit} handleClick={handleClick} minLimit={minLimit} device={device} units={units} selectable={false} apiRoute={apiRoute}></RangePicker>
          {/* <div className={styles.vspacer}></div> */}
          {/* <RangedGrapics minLimit={minLimit} device={device} labels={units} selectable={false} dateStart={minLimit} route={apiRoute}/> */}
        </TabPanel>
    </div>
  );
}
