import React from 'react';
// import Modal from '@material-ui/core/Modal';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import { Button, IconButton, Link, Typography, Grid } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#ffa600d3',
        color: 'white',
    },

    framed: {
        "& p": {
            fontSize: '0.8rem',
            lineHeight: '1.4rem'
        },
        "& a": {
            fontSize: '0.8rem'
        }
    },

    "doLink": {
        '& .MuiButton-label': {
            fontWeight: 'bold', 
            textDecoration:'underline'
        }
    }
}));

export default function Contact({ onClose }) {
    const [open, setOpen] = React.useState(true);
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Grid container alignItems='center' justify='center'>
            <Grid container xs={12} style={{height: '3rem'}}></Grid>
            <Grid container xs={12} md={8} alignItems='center' justify='center' style={{border: '2px solid #ffa600d3', borderRadius: '4px'}}>
                <Typography align="center">{t('contact_message')}</Typography>
                <Grid container xs={12}>
                    <Grid container xs={6} className={classes.framed} style={{lineHeight: '', borderTop: '2px solid #ffa600d3', borderRight: '1px solid #ffa600d3', borderBottom: '1px solid #ffa600d3'}} alignItems='center' justify='center'>
                        <Typography align="center">Horario</Typography>
                    </Grid>
                    <Grid container xs={6} className={classes.framed} style={{borderTop: '2px solid #ffa600d3', borderLeft: '1px solid #ffa600d3', borderBottom: '1px solid #ffa600d3'}} alignItems='center' justify='center'>
                        <Typography align="center">Teléfono de atención al inquilino</Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid container xs={6} className={classes.framed} style={{borderRight: '1px solid #ffa600d3',borderTop:'1px solid #ffa600d3'}} alignItems='center' justify='center'>
                        <Typography align="center">L-V (09:00 a 18:00)</Typography>
                    </Grid>
                    <Grid container xs={6} className={classes.framed} style={{borderLeft: '1px solid #ffa600d3',borderTop:'1px solid #ffa600d3'}} alignItems='center' justify='center'>
                        {/* <Button>
                            <Typography align="center">696 508 403</Typography>
                        </Button> */}
                        <Link href={"tel:696 508 403"}>
                            <Button variant="contained" className={classes.doLink} startIcon={<PhoneIcon/>} size="small" style={{padding:'0 0.2em 0 0',background:'transparent',boxShadow:'none'}}>696 508 403</Button>
                        </Link>

                        {/* <IconButton color="primary"><PhoneIcon/></IconButton>
                        <Link href={"tel:696 508 403"}>696 508 403</Link> */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container xs={12} style={{height: '1rem'}}></Grid>
            <Grid container xs={12} md={8} alignItems='center' justify='center'>
                <List>
                    <ListItem button>
                        <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <LanguageIcon/>
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'dhvallecas.es'} />
                    </ListItem>
                    <ListItem button>
                        <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <MailIcon/>
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'info@dhvallecas.es'} />
                    </ListItem>
                    {/* <ListItem button>
                        <ListItemAvatar>
                        <Avatar className={classes.avatar}>
                            <PhoneIcon/>
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={'910 000 000'} />
                    </ListItem> */}
                </List>
            </Grid>
            <Grid container xs={12} style={{height: '3rem'}}></Grid>
        </Grid>
    );
}