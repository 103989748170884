import React, { Fragment, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/eu'
//import 'moment/locale/en'
import MomentUtils from '@date-io/moment';
import { Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, MenuItem, MenuList, Popper, Radio, RadioGroup, Switch, Typography } from '@material-ui/core'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import WeekPicker from '../pickers/WeekPicker'
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    }, paper: {
        zIndex: 2
    },spacer:{
        padding: '4px 0'
    }
}));

export default function SimpleDateSelector(props) {
    let minLimit;
    const classes = useStyles();
    const {userDateStart,handleClick } = props;
    const [resolution, setResolution] = useState('min')
    const [dateblock, setDateBlock] = useState('daily')
    const [initDate, setInitDate] = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [ranged, setRanged] = React.useState(false);
    const { t, i18n } = useTranslation('date_selector');
    const options = [
        { label: t('daily'), value: 'daily' },
        { label: t('weekly'), value: 'weekly' },
        { label: t('monthly'), value: 'monthly' }

    ]
    let defIndex = 0;
    const [selectedIndex, setSelectedIndex] = React.useState(defIndex);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    moment.locale(i18n.language)
    if (minLimit !== undefined && (initDate === undefined || moment(initDate) <= moment(minLimit))) {
        initDate = minLimit
    };
    const handleDateStartChange=(value)=>{
        setInitDate(moment(new Date(value)))
        if (dateblock === 'monthly'){
            setInitDate(moment(new Date(value.format("YYYY-MM-01"))))
            if (!ranged){
                setEndDate(moment(new Date(value.format("YYYY-MM-") + value.daysInMonth())))
            }
            
        } else if (dateblock === 'weekly'){
            setInitDate(moment(new Date(value['start'].format("YYYY-MM-DD"))))
            if (!ranged){
                setEndDate(moment(new Date(value['end'].format("YYYY-MM-DD"))))
            }
        }
        else {
            setInitDate(value)
        }
        handleClick(dateblock, resolution, value, endDate)
    }
    const handleDateEndChange=(value)=>{
        console.log('handleDateEndChange',value)
        if (dateblock === 'weekly') {
            if (ranged) {
                setEndDate(moment(new Date(value['end'].format("YYYY-MM-DD"))))
            }
        } else {
            setEndDate(value)
        }
    }
    
    return <Grid container>
        <Grid container item xs={12} >
            <FormControl component="fieldset">
                <Grid container item xs={12} className={classes.spacer}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                        <>
                        {dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item xs={ranged?5:12}><DatePicker value={initDate} minDate={userDateStart} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                        {dateblock === 'weekly' && <Grid item xs={ranged ? 5 : 12}><WeekPicker value={initDate} minDate={userDateStart} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                        {dateblock === 'monthly' && <Grid item xs={ranged ? 5 : 12}><DatePicker value={initDate} minDate={userDateStart} views={["month", "year"]} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                        {ranged && <Grid container item xs={2}></Grid>}
                        {ranged && dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item xs={5}><DatePicker value={endDate} minDate={initDate} disableFuture={true} onChange={handleDateEndChange} /></Grid>}
                        {ranged && dateblock === 'weekly' && <Grid item xs={5}><WeekPicker value={endDate} minDate={initDate} disableFuture={true} onChange={handleDateEndChange} /></Grid>}
                        {ranged && dateblock === 'monthly' && <Grid item xs={5}><DatePicker value={endDate} minDate={initDate} views={["month", "year"]} disableFuture={true} onChange={handleDateEndChange} /></Grid>}
                        </>
                    </MuiPickersUtilsProvider>
                </Grid>
            </FormControl>
        </Grid>
    </Grid>
}
