import React, {useEffect, useState, useContext, createContext} from 'react'
import moment from 'moment'
import LogoutPopup from './idleTimer'
import ErrorPopup from './errorPopup';
import { useTranslation } from 'react-i18next';
const localStorageKey = '__auth_provider_token__'
const authURL = 'dexcell';//process.env.REACT_APP_AUTH_URL
const authContext = createContext();



// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({children}){
    const auth = useAuthProvider();
    return <authContext.Provider value={auth}>
        <LogoutPopup onTimeExpires={auth.logout} onIdle={auth.updateExpiration} expireTime={1800} warnTime={1500}/>
        {auth.customError && <ErrorPopup onEnd={auth.logout} title={auth.errorTitle} msg={auth.errorMsg}/>}
        {children}
        </authContext.Provider>;
}


// Hook for child components to get the auth object ...
// ... and update when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

export const getStartContractDate=({user})=>{
    let limit = moment(user.signup_date)
    if (limit.isBefore(moment('2020-01-01T00:00:00')))
        return moment('2020-01-01T00:00:00').format('YYYY-MM-DD')
    return limit.format('YYYY-MM-DD')
}
// Provider hook that creates auth object and handles state
function useAuthProvider() {

    const raiseTimeout = function(){
        logout();
        
    }
    const raiseWarn = function(){
        console.warn('raise warning');
    }
    async function utils_client(endpoint, data = {}) {
        if (data.token === undefined && localStorage.getItem(localStorageKey) !== null && JSON.parse(localStorage.getItem(localStorageKey)).token !== undefined)
            data.token = JSON.parse(localStorage.getItem(localStorageKey)).token
        const config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
        }

        return window.fetch(`${endpoint}`, config).then(async response => {
            const data = await response.json()
            if (response.ok) {
                if (data.ok === false && data.error === 401) {
                    logout()
                    return Promise.reject(data)
                }
                return data
            } else {
                return Promise.reject(data)
            }
        })
    }
    async function client(endpoint, data) {
        if (endpoint !== 'login'){
            if (data.token === undefined && localStorage.getItem(localStorageKey) !== null && JSON.parse(localStorage.getItem(localStorageKey)).token !== undefined)
                data.token = JSON.parse(localStorage.getItem(localStorageKey)).token
        }
        const config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
        }

        //console.log("=============>" + `${authURL}/${endpoint}`);
        return window.fetch(`${authURL}/${endpoint}`, config).then(async response => {
            const data = await response.json()
            if (response.ok) {
                if (data.ok === false && (data.server_status === 401 || data.server_status === 429 || data.error === 401)) {
                    if (data.server_status === 429){
                        
                        return Promise.reject(data)
                    }
                    else {
                        logout()
                        return Promise.reject(data.data)
                    }
                }
                return data.data
            } else {
                return Promise.reject(data.data)
            }
        })
    }
    async function tecnalia_client(endpoint, data) {
        if (data.token === undefined && localStorage.getItem(localStorageKey) !== null && JSON.parse(localStorage.getItem(localStorageKey)).token !== undefined)
            data.token = JSON.parse(localStorage.getItem(localStorageKey)).token
        const config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
        }

        return window.fetch(`tecnalia/${endpoint}`, config).then(async response => {
            const data = await response.json()
            if (response.ok) {
                if (data.ok === false && data.error === 401) {
                    logout()
                    return Promise.reject(data)
                }
                return data
            } else {
                return Promise.reject(data)
            }
        })
    }
    async function ondata_client(endpoint, data) {
        if (data.token === undefined && localStorage.getItem(localStorageKey) !== null && JSON.parse(localStorage.getItem(localStorageKey)).token !== undefined)
            data.token = JSON.parse(localStorage.getItem(localStorageKey)).token
        const config = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
        }

        return window.fetch(`ondata/${endpoint}`, config).then(async response => {
            const data = await response.json()
            if (response.ok) {
                if (data.ok === false && data.error === 401) {
                    logout()
                    return Promise.reject(data)
                }
                return data
            } else {
                return Promise.reject(data)
            }
        })
    }
    //const monitor = useMonitor({timeout:300,warningTimeout:280,onWarn:raiseWarn, onTimeOut:raiseTimeout});
    const [user, setUser] = useState(null);
    const [expiration, setExpiration] = useState();
    const [customError, setCustomError] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const {t,i18n} = useTranslation()
    const login = ({username, password}) => {
        setCustomError(false)
        return new Promise((resolve, reject) => {
            client('login', { username, password }).then(({ ok, user })=> {
                if (ok) {
                    window.localStorage.setItem(localStorageKey, JSON.stringify(user))
                    
                    getCurrentUser().then(user => {
                        setExpiration(moment().add(30, 'minutes').unix()) 
                        window.localStorage.setItem('last_login', moment().add(30, 'minutes').unix())
                        setUser(user);
                        resolve(user);
                    }).catch((error)=>{
                        if (error=='no_locations'){
                            setErrorTitle(t('no_locations'))
                            setErrorMsg(t('no_locations_text'))
                            setCustomError(true)
                            resolve(ok);
                        }
                    })
                } else {
                    // setErrorTitle(t('bad_login'))
                    // setErrorMsg(t('bad_login_text'))
                    // setCustomError(true)
                    reject(ok);
                }
                
            }).catch((error)=>{
                //reject(new CustomError(error.code, error.description));
                console.log(error)
                if (error.server_status === 429){
                    setErrorTitle(t(error.error))
                    setErrorMsg(t(error.error+'_text',error.data))
                    setCustomError(true)
                    reject(error.ok)
                }
            });
        });
    };
    const updateExpiration = ()=>{
        if (moment().unix() >= moment.unix(parseInt(localStorage.getItem('last_login'))).unix()) {
            window.localStorage.setItem('last_login', moment().add(30, 'minutes').unix())
        } else if (user){
            logout();
        }
    }
    // const signup = (email, password) => {
    //     return new Promise((resolve, reject) => {
    //         auth0.signup(
    //             {
    //                 connection: auth0Realm,
    //                 email: email,
    //                 password: password
    //             },
    //             (error, response) => {
    //                 if (error) {
    //                     reject(new CustomError(error.code, error.message));
    //                 } else {
    //                     // Automatically signin the user
    //                     login(email, password).then(() => {
    //                         resolve();
    //                     });
    //                 }
    //             }
    //         );
    //     });
    // };

    const logout = (message) => {
        setUser(false);
        window.localStorage.removeItem(localStorageKey);
        window.localStorage.removeItem('last_login');
    };

    // const sendPasswordResetEmail = email => {
    //     return new Promise((resolve, reject) => {
    //         auth0.changePassword(
    //             {
    //                 connection: "Username-Password-Authentication",
    //                 email: email
    //             },
    //             (error, response) => {
    //                 if (error) {
    //                     return reject(error);
    //                 } else {
    //                     resolve(true);
    //                 }
    //             }
    //         );
    //     });
    // };


    const confirmPasswordReset = (password, code) => {
        return Promise.reject(
            new CustomError(
                "not_needed",
                "Auth0 handles the password reset flow for you. You can remove this section or page."
            )
        );
    };

    // Get the current user using stored access_token
    const getCurrentUser = () => {
        return new Promise((resolve, reject) => {
            let userInfo = false;
            try {
                userInfo = JSON.parse(window.localStorage.getItem(localStorageKey))
            } catch {
                userInfo = window.localStorage.getItem(localStorageKey)
            }
            if (userInfo) {
                if (userInfo.access_policy.locations.length === 0) { 
                    reject('no_locations')
                    return
                }
                resolve(userInfo)
            } else {
                resolve(false);
            }
        });
    };

    // Get user on mount
    useEffect(() => {
        getCurrentUser().then(user => {
            setUser(user);
        }).catch(()=>{
            logout()
        });
    }, []);
    useEffect(()=>{
        //check expiration
        if (localStorage.getItem('last_login') !== null){
            if (moment().unix() >= moment.unix(parseInt(localStorage.getItem('last_login'))).unix()){
                //console.log('loggin out')
                logout();
        }}
    })
    return {
        user,
        login,
        client,
        utils_client,
        tecnalia_client,
        ondata_client,
        logout,
        updateExpiration,
        customError,
        errorTitle,
        errorMsg
        //sendPasswordResetEmail,
        //confirmPasswordReset
    };
}

function CustomError(code, message) {
    // Auth0 doesn't always give us a human readable error message ...
    // ... so as a backup we display the error code ("invalid_password", etc).
    const displayMessage = typeof message === "string" ? message : code;
    const error = new Error(displayMessage);
    error.code = code;
    return error;
}