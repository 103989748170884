import React, { useState } from 'react'


import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { Box, Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { DatePicker } from "@material-ui/pickers";
import { Chart, Bar, Polar, Pie } from 'react-chartjs-2';
import { getStartContractDate, useAuth } from '../utils/auth';
import moment from 'moment'
import { spacing } from '@material-ui/system';
import 'moment/locale/es'
import 'moment/locale/eu'
import ServicePicker from '../components/ServicePicker'
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { setDate } from 'date-fns';
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  errorField: {
    color: 'red'
  }
}));
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
export default function Services({ value }) {
  const minLimit = getStartContractDate(useAuth());
  const { ondata_client } = useAuth();
  const [options, setOptions] = useState({})
  const [selectedDate, handleDateChange] = useState(new Date());
  const { t, i18n } = useTranslation('services');
  const classes = useStyles();
  const width = useWidth();
  const isSmallScreen = /xs|sm|md/.test(width);
  const [data, setData] = useState([])
  const [isRequesting, setRequesting] = useState(false)
  const [hasErrors, setErrors] = useState(false)
  const [currentError, setCurrentError] = useState([])
  // const [initDate, setInitDate] = useState(moment(new Date()));
  // const [endDate, setEndDate] = useState(moment(new Date()));

  if (isSmallScreen) {
    options['maintainAspectRatio'] = false;
  } else {
    options['maintainAspectRatio'] = false;
    options['responsive'] = true;
  }

  let agregado_options = options

  agregado_options['legend'] = {
    display: true,
    labels: {
      boxWidth: 18,
      fontSize: 12,
      generateLabels: function (chart) {
        var labels = Chart.defaults.global.legend.labels.generateLabels(chart);
        labels = labels.map((label) => {
          if (Array.isArray(label.text)) {
            label.text = t(label.text[0], label.text[1])
          } else {
            label.text = t(label.text)
          }
          return label
        })
        
        return labels;
      }
    }
  }
  agregado_options['tooltips'] = {
    callbacks: {
      label: function (ttitem, data) {
        var label = ''
        if (Array.isArray(data.datasets[ttitem.datasetIndex].label)) {
          label = t(data.datasets[ttitem.datasetIndex].label[0], data.datasets[ttitem.datasetIndex].label[1])
        } else {
          label = t(data.datasets[ttitem.datasetIndex].label) || '';
        }
        
        if (label) {
          label += ': ';
        }
        label += parseFloat(data.datasets[ttitem.datasetIndex].data[ttitem.index]).toFixed(2) + ' ' + data.datasets[ttitem.datasetIndex].unit
        return label;
      }
    }
  }

  const processRequest = async function (value, startdate, enddate,resolution) {
    // if (moment(startdate) < moment(minLimit)) startdate = minLimit;
    startdate = startdate.format().replace('+', '%2B')
    enddate = enddate.format().replace('+', '%2B')
    return await ondata_client('graphs', { value, startdate, enddate, resolution }).then((response) => {
      console.log(response)
      if ('error' in response) {
        setCurrentError(response['error'])
        throw response
      }
      setData(response)
      // response['options']['scales']['yAxes'][0]['ticks']['callback'] = function (value, index, values) {
      //     if (index === values.length - 1) return Math.min(Math.min.apply(this, response['graph']['datasets'][0]['data']), Math.min.apply(this, response['graph']['datasets'][0]['data']))
      //     else if (index === 0) return Math.max(Math.max.apply(this, response['graph']['datasets'][0]['data']), Math.max.apply(this, response['graph']['datasets'][0]['data']))
      //     else return ''
      //}
      setRequesting(false)

    }).catch((error) => {
      console.error(error);

      setErrors(true)
      setRequesting(false)
    });
  }

  const handleClick = (dateblock, resolution, initDate, endDate) => {
    setErrors(false)
    setRequesting(true)
    setCurrentError([])
    processRequest(value, initDate, endDate, resolution)
  }

  return <Grid spacing={0} container>
    <Grid item xs={12}><Typography>{t('service_description')}</Typography></Grid>
    <Grid item container xs={12}>
      <ServicePicker userDataStart={minLimit} handleClick={handleClick}></ServicePicker>
    </Grid>

    <Grid item container xs={12}>
      {isRequesting && <Grid item container xs={12} justify='center'><Typography><Box fontWeight='bold'>{t('waiting_response')}</Box></Typography></Grid>}
      {hasErrors && <Grid item container xs={12} justify='center'><Typography className={classes.errorField}><Box fontWeight='bold'>{t(currentError[0])} [{currentError[1]}]: {t(currentError[2])}</Box></Typography></Grid>}
      {!hasErrors && !isRequesting && data.length!==0 && <Grid item container xs={12} spacing={4}>
            {/* <Grid item xs={12}>t('potencia_termica')</Grid> */}
        <Grid container item xs={12} spacing={4}>
              <Grid item xs={12}>
                <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}> 
              <Bar data={data[0]} options={agregado_options} />
                </Box>
              </Grid>
          <Grid container item xs={12} spacing={4}>
                <Grid container item md={6} xs={12}>
                  <Grid item xs={12}>
                    <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}>
                  <Bar data={data[1]} options={agregado_options} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}>
                  <Bar data={data[2]} options={agregado_options} />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={6}>
              <Box minHeight={isSmallScreen ? 300 : 500} maxHeight={500} width={'100%'} my="auto">
                <Bar data={data[3]} options={agregado_options} />
              </Box>
                </Grid>
              </Grid>

            </Grid>

      </Grid>}
    </Grid>
  </Grid>
}