import React, { useEffect } from 'react'
import {useAuth} from '../utils/auth'
import LocationSelector from './locationSelector'
import LanguageSelector from './languageSelector'
import {ButtonGroup, Button, Grid,Typography, AppBar, useMediaQuery, IconButton, Menu, MenuItem,   Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField} from '@material-ui/core'
import PublishIcon from '@material-ui/icons/Publish';
import Contact from '../parts/Contact'
import  logoSVG  from '../static/images/logo-vallecas.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import {useTranslation} from 'react-i18next'
import Guide from '../parts/Guide'
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      background: '#e3f4fa',
      paddingBottom:'0.5em',
      color: 'black'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      minHeight: 128,
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      
    },
    title: {
      marginLeft: '0.8rem',
      flexGrow: 1,
      alignSelf: 'flex-end',
    },
    adjust_image: {
        //marginLeft: '1em',
        //marginRight: '0.8em',
        maxWidth: '4.8rem',
        minWidth: '4rem',
        //maxHeight: '4.2rem',
        display: 'block'
    },
    spacer: {
        minWidth: '0.8rem'
    }
  }));

  function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
      }, null) || 'xs'
    );
  }

export function Header(props) {
    const { t } = useTranslation();
    const {user} = useAuth();
    let buttons;
    let userText = "";
    let locationSelector = undefined
    if(props.clausula) {
        locationSelector = LanguageSelector(props)
    }
    else {
        if (user === null || user === false){
            buttons = UnauthHeader();
            userText = t('identification_required')
            locationSelector = LanguageSelector(props)
            
        }else{
            buttons = AuthHeader({user});
            userText = t('welcome') ;
            locationSelector = LocationSelector(props, user)
        }
    }
    return DefaultHeader(buttons,userText,locationSelector)
}


function UnauthHeader(){ // If is required a header in login view
    //const { t, i18n } = useTranslation();
    //const classes = useStyles();
    // return (<ButtonGroup variant="text" color="primary" aria-label="text primary button group" edge="end">
    //     <Button>{t('user_guide')}</Button>
    // </ButtonGroup>)
    return <></>
}

function AuthHeader({user}){ 
    const { t } = useTranslation();
    const { utils_client, logout } = useAuth();

    /*******************/ 
    const [fileData, setFileData] = React.useState("");

    const [readyToUpload, setReadyToUpload] = React.useState(false);

    const [popupOpen, setOpen] = React.useState(false);
    const handlePopupOpen = () => {
        setOpen(true);
        // document.querySelectorAll('.uploadButton').forEach((el)=>{
        //     el.classList.add('Mui-disabled');
        // });
    };
    const handlePopupClose = () => {
        setOpen(false);
        setReadyToUpload(false);
        //filedata = "";
        setFileData("");
    };

    const [isValidUser, setValidUser] = React.useState(false);

    const checkUser = async function () {
        return await utils_client('isvalidusertoupload').then((response) => {
            return response.valid_user || false;
        });
    }

    const getRecommendations = async function () {
        return await utils_client('recommendations').then((response) => {
            var result = [];
            if(response.ok && response.lines != undefined) {
                for(var line of response.lines) {
                    result.push([<Grid container xs={12} style={{marginTop: '3rem'}}><Typography align="left">{line}</Typography></Grid>]);
                }
            }
            return result;
        });
    }

    const uploadFile = async function () {
        console.log("Uploading file ", fileData)    
        return await utils_client('uploadrecommendation', {fileData}).then((response) => {
            var result = [];
            if(response.ok) {
                console.log("File upload success");
            }
            return result;
        });
    }
    const handleCapture = function (event) {
        const reader = new FileReader();
        reader.onload = (e) => {
            //filedata = e.target.result;
            setFileData(e.target.result);
            //document.getElementsByClassName('uploadButton').removeAttribute('disabled');
            // document.querySelectorAll('.uploadButton').forEach((el)=>{
            //     el.classList.remove('Mui-disabled');
            // });
            setReadyToUpload(true);
        }
        var file = event.target.files[0];
        reader.readAsDataURL(file);
    }
    const handleUpload = () => {
        uploadFile();
        handlePopupClose();
    }
    /*******************/ 
    
    const [showResults, setShowResults] = React.useState(false)
    const [showGuide, setShowGuide] = React.useState(false)
    const handleClickContacto = () => {
        if (!showResults) { setShowResults(true)};
        setAnchorEl(null);
    }
    const handleContactClose=()=>{
        setShowResults(false);
    }
    const handleGuideClose=()=>{
        setShowGuide(false)
    }
    const handleClickGuide = ()=>{
        if (!showGuide){ setShowGuide(true)};
        setAnchorEl(null);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClose = () => {
        setAnchorEl(null);
       
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClickLogout=(event)=>{
        logout();
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl);
    const width = useWidth();
    const isSmallScreen = /xs|sm/.test(width);
    const classes = useStyles();

    useEffect(()=>{
        checkUser().then((result)=>{
            setValidUser(result);
        });
    }, []);

    if (!isSmallScreen) {
        return (<><ButtonGroup variant="text" color="primary" aria-label="text primary button group" edge="end">
            {isValidUser && (
                <Grid container>
                    <Button 
                        style={{margin: '2px 0'}}
                        variant="contained"
                        color="primary"
                        aria-controls={popupOpen ? 'split-button-menu' : undefined}
                        aria-expanded={popupOpen ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handlePopupOpen}
                        startIcon={<PublishIcon/>}>
                            Recomendaciones
                    </Button>
                    <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Subir fichero de texto</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            Seleccione un fichero de texto con una línea por cada párrafo a mostrar o vacío si quiere ocultar la pestaña
                        </DialogContentText>
                        <Button variant="contained" component="label">
                            Cargar fichero
                            <input
                                type="file"
                                accept=".txt"
                                hidden
                                onChange={handleCapture}
                            />
                        </Button>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleUpload} color="primary" className={"uploadButton"} disabled={!readyToUpload}>
                            Subir
                        </Button>
                        <Button onClick={handlePopupClose} color="primary">
                            Cancelar
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            )}

            <Button onClick={handleClickLogout}>{t('logout')}</Button>
            </ButtonGroup>
            <div>{ showResults ? <Contact  onClose={handleContactClose}/> : null }</div>
                <div>{showGuide ? <Guide onClose={handleGuideClose}/>:null}</div>
            </>)
    } else {
        return <>
        {/* <Button color="primary"><Badge badgeContent={1} color="secondary" anchorOrigin={{vertical: 'bottom', horizontal: 'right', }}><Notifications /></Badge></Button> */}
        <IconButton color="primary" edge="end" className={classes.menuButton} aria-label="menu" onClick={handleMenu}>
            <MenuIcon />
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
            open={open}
            onClose={handleClose}
            >
                {isValidUser && (
                    <Grid container alignItems='center' justify='center' xs={12} md={8}>
                        <MenuItem 
                            style={{margin: '2px 0'}}
                            variant="contained"
                            color="primary"
                            aria-controls={popupOpen ? 'split-button-menu' : undefined}
                            aria-expanded={popupOpen ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handlePopupOpen}
                            startIcon={<PublishIcon/>}>
                                Recomendaciones
                        </MenuItem>
                        <Dialog open={popupOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Subir fichero de texto</DialogTitle>
                            <DialogContent>
                            <DialogContentText>
                                Seleccione un fichero de texto con una línea por cada párrafo a mostrar o vacío si quiere ocultar la pestaña
                            </DialogContentText>
                            <Button variant="contained" component="label">
                                Cargar fichero
                                <input
                                    type="file"
                                    accept=".txt"
                                    hidden
                                    onChange={handleCapture}
                                />
                            </Button>
                            </DialogContent>
                            <DialogActions>
                            <Button onClick={handleUpload} color="primary" className={"uploadButton"} disabled={!readyToUpload}>
                                Subir
                            </Button>
                            <Button onClick={handlePopupClose} color="primary">
                                Cancelar
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                )}
                <MenuItem onClick={handleClickLogout}>{t('logout')}</MenuItem>
        </Menu>
        <div>{ showResults ? <Contact  onClose={handleContactClose}/> : null }</div>
            <div>{showGuide ? <Guide onClose={handleGuideClose}/> : null}</div>
        </>
    }
}

function DefaultHeader(buttons, userText, locationSelector){
    const classes = useStyles();
    
    return (<AppBar position="static" elevation={0} className={classes.root}>
         <Grid container>
             <Grid item container xs={2}  alignItems='center' justify='center'>
                <img alt='logo' className={classes.adjust_image} src={logoSVG} />
             </Grid>
             <Grid item container xs={10}>
                <Grid item container xs={12} justify='flex-end' >{buttons}</Grid>
                <Grid item xs={7} sm={8}>
                    <Typography className={classes.title} variant="h5">
                     {userText}
                    </Typography>
                </Grid>
                    {locationSelector?locationSelector:''}
             </Grid>
         </Grid>
    </AppBar>)
}