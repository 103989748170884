import React, { useEffect, useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel, Typography, Grid, Box, useMediaQuery, useTheme } from '@material-ui/core';
import RangedGrapics from '../components/RangedGraphics';
import {useTranslation} from 'react-i18next';
import SimpleDateSelector from '../components/SimpleDateSelector';
import { getStartContractDate, useAuth } from '../utils/auth';
import { makeStyles } from '@material-ui/core/styles';
import {Bar} from 'react-chartjs-2';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
const useStyles = makeStyles({
  vspacer: {
    height:'2rem'
  },

  vcenter: {
    height: "12em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
});

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
      keys.reduce((output, key) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const matches = useMediaQuery(theme.breakpoints.up(key));
          return !output && matches ? key : output;
      }, null) || 'xs'
  );
}

export default function DailyConsumption({value}) {
  const classes = useStyles();
  const { user } = useAuth();
  const {client} = useAuth();
  const [data, setData] = React.useState({});
  const route = 'readings/thermenergy';
  const minLimit = getStartContractDate({ user });
  const { t } = useTranslation(['consumption','date_selector']);
  const [ currentResolution, setCurrentResolution ] = useState('H');
  const [ selectedDay, setSelectedDay ] = useState(moment().format("YYYY-MM-DD"));
  const [ isEmpty, setIsEmpty ] = useState(false);

  const width = useWidth();
  const isSmallScreen = /xs|sm/.test(width);

  const doRequest = (startdate) => {
    let date = selectedDay;
    if(startdate != undefined) {
      date = startdate.format("YYYY-MM-DD");
      setSelectedDay(date);
    }
    console.log(date);
    startdate = date + "T00:00:00";
    let enddate = date + "T23:59:00";
    let dateblock = "daily";
    processRequest(value, startdate, enddate, dateblock, currentResolution);
  }

  const handleClick = (dateblock, r, startdate, enddate) => {
    doRequest(startdate);
  }

  const processRequest = async function(device,startdate,enddate,dateblock,resolution){
    if (moment(startdate) < moment(minLimit)) startdate = minLimit; 
    return await client(route,{device,startdate,enddate,resolution}).then((response)=>{
      if(response.datasets[0].data.length == 0) {
        setIsEmpty(true);
      }
      else {
        setIsEmpty(false);
      }
      
      setData(response)
    });
  }

  const handleResolutionChange = (event) => {
    setCurrentResolution(event.target.value);
  }
  useEffect(() => {
    doRequest();
  }, [currentResolution]);

  useEffect(()=>{
    if (value !== ''){
      doRequest();
    }
  }, [value])

  return <Grid spacing={0} container>
      {/* <Typography variant='h4'>{t('daily_consumption')}</Typography> */}
      {/* <Grid item container xs={12}>
          <SimpleDateSelector handleClick={handleClick} userDateStart={minLimit} />
      </Grid>
      <Grid item xs={12} className={classes.vspacer}></Grid>
      <Grid container item xs={12} className={classes.spacer}>
          <FormControl component="fieldset">
              <FormLabel component="legend">{t('resolution')}</FormLabel>
              <RadioGroup row aria-label="resolution" name="resolution" value={currentResolution} onChange={handleResolutionChange}>
                  <FormControlLabel
                      value="QH"
                      control={<Radio color="primary" />}
                      label={t("15min")}
                      labelPlacement="top"

                  />
                  <FormControlLabel
                      value="H"
                      control={<Radio color="primary" />}
                      label={t("Hora")}
                      labelPlacement="top"
                  />
              </RadioGroup>
          </FormControl>
      </Grid> */}
      <Grid item container xs={12}>
        <Box minHeight={ isSmallScreen?300:500} width={'100%'}>
        {console.log(isEmpty)}
        {isEmpty
          ? <Typography align="center" variant='h6' className={classes.vcenter}>{t('no_data')}</Typography>
          : <Bar data={data} options={isSmallScreen ? { 'maintainAspectRatio': false } : { 'maintainAspectRatio': false,'responsive': true }}/>
        }
        </Box>
      </Grid>
  </Grid>
}