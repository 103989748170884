import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import 'moment/locale/eu'
//import 'moment/locale/en'
import MomentUtils from '@date-io/moment';
import RangedGrapics from '../components/RangedGraphics';
import { Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, MenuItem, MenuList, Popper, Radio, RadioGroup, Switch, Typography } from '@material-ui/core'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import WeekPicker from '../pickers/WeekPicker'
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    paper: {
        zIndex: 2
    },
    spacer: {
        padding: '1rem 0',
        margin:'0 0 2rem 0'
    },
    centerText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: '1rem !important'
    },
    shadowed: {
        backgroundColor: 'rgb(255, 255, 255)',
        borderRadius: '4px',
        margin:'0 0 2rem 0'
    },
    vspacer: {
        marginBottom:'2rem'
    },
}));

export default function RangePicker(props) {
    const classes = useStyles();

    const { userDateStart, handleClick, minLimit, device, units, apiRoute} = props;

    const [resolution, setResolution] = useState('hour')
    const [dateblock, setDateBlock] = useState('daily')
    const [initDate, setInitDate] = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [ranged, setRanged] = React.useState(false);
    
    const [resolutionQuery, setResolutionQuery] = React.useState('H');
    const [initDateQuery, setInitDateQuery] = React.useState(initDate.format('YYYY-MM-DD HH:mm'));
    const [endDateQuery, setEndDateQuery] = React.useState(endDate.format('YYYY-MM-DD HH:mm'));

    const { t, i18n } = useTranslation('date_selector');
    
    const options = [
        { label: t('daily'), value: 'daily' },
        { label: t('monthly'), value: 'monthly' }
    ]
    
    let defIndex = 0;
    const [selectedIndex, setSelectedIndex] = React.useState(defIndex);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    
    moment.locale(i18n.language);

    if (minLimit !== undefined && (initDate === undefined || moment(initDate) <= moment(minLimit))) {
        initDate = minLimit
    };

    const handleDateBlockChange = (event, index) => {
        //console.log("handleDateBlockChange", options[index].value);
        setDateBlock(options[index].value)
        setSelectedIndex(index);
        setOpen(false);
        if (options[index].value === 'daily' && !ranged)
            setResolution('hour')
        else
            setResolution('day')
    }

    const handleDateStartChange = (value) => {
        //console.log('handleDateStartChange',value);
        // if (dateblock === 'monthly') {
        //     setInitDate(value.clone().startOf('month'))
        //     if (!ranged) {
        //         setEndDate(value.clone().endOf('month'))
        //     }
        // }
        // else {
        //     setInitDate(value.clone().startOf('day'))
        //     if (!ranged) {
        //         setEndDate(value.clone().endOf('day'))
        //     }
        // }
        if (dateblock === 'monthly') {
            setInitDate(value.clone().startOf('month'))
            if (!ranged) {
                setEndDate(value.clone().endOf('month'))
            }
        }
        else {
            //var start = value.clone().startOf('day');
            setInitDate(value.clone().startOf('day'));
            //console.log(start.toISOString());
            if (!ranged) {
                //var end = value.clone().endOf('day');
                setEndDate(value.clone().endOf('day'));
                //console.log(end.toISOString());
            }
        }
    }

    const handleDateEndChange = (value) => {
        //console.log('handleDateEndChange', value);
        if (dateblock === 'weekly') {
            if (ranged) {
                setEndDate(value['end'].clone().endOf('day'))
            }
        } else {
            setEndDate(value.clone().endOf('day'))
        }
    }

    // const handleRangedChange = (event) => {
    //     setRanged(event.target.checked);
    //     if (event.target.checked) setEndDate(moment())
    // };
    const makeJSDateObject = function (date) {
        if (moment.isMoment(date)) {
            return (date).clone().toDate();
        }
        if (date instanceof Date) {
            return new Date(date.getTime());
        }

        return date; // handle case with invalid input
    }
    // Los cambios son asíncronos, así que hay que capturarlos por separado
    useEffect(() => {
        //console.log("UPDATING CHART", dateblock, resolution, initDate, endDate, initDate.toISOString(), initDate.format('YYYY-MM-DD HH:mm'), endDate.toISOString(), endDate.format('YYYY-MM-DD HH:mm'));

        var r = 'H';
        if(resolution == 'day')
            r = 'D';
        else if (resolution == 'month')
            r = 'M';
        setResolutionQuery(r);

        if (dateblock === 'monthly') {
            setInitDateQuery(initDate.clone().startOf('month').format('YYYY-MM-DD HH:mm'));
            if (!ranged) {
                setEndDateQuery(endDate.clone().endOf('month').format('YYYY-MM-DD HH:mm'));
            }
        }
        else {
            setInitDateQuery(initDate.clone().startOf('day').format('YYYY-MM-DD HH:mm'));
            if (!ranged) {
                setEndDateQuery(endDate.clone().endOf('day').format('YYYY-MM-DD HH:mm'));
            }
        }

        // if (dateblock === 'weekly')
        //     handleDateStartChange({ start: moment(startOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })), end: moment(endOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })) });
        // else
        //     handleDateStartChange(initDate);
    }, [dateblock, initDate, endDate, resolution]);

    // useEffect(() => {
    //     if (!ranged) {
    //         if (dateblock === 'daily' || dateblock === 'monthly') handleDateStartChange(initDate)
    //         else if (dateblock === 'weekly') {
    //             setEndDate(moment(endOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })))
    //         }
    //         if (dateblock === 'daily'){
    //             setResolution('hour')
    //         } else{
    //             setResolution('day')
    //         }
    //     }
    //     else if (ranged) {
    //         if (dateblock === 'weekly') handleDateEndChange({ 'end': moment() })
    //         else handleDateEndChange(moment())
    //         if (dateblock === 'daily'){
    //             setResolution('hour')
    //         }
    //         else {
    //             setResolution('month')
    //         }
    //     }
    // }, [ranged])
    const handleResolutionChange = (event) => {
        //console.log("handleResolutionChange", event.target.value);
        setResolution(event.target.value);
    }

    const handleTimeChange = (event) => {
        //console.log("handleTimeChange", event.target.value);
    }

    // const handleSelectorClick = () => {
    //     handleClick(dateblock, resolution, initDate, endDate)
    // }

    // const handleMenuItemClick = (event, index) => {
    //     setSelectedIndex(index);
    //     setOpen(false);
    // };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return <Grid container>
        <Grid container alignItems="left" spacing={2} className={classes.shadowed} >
            <Grid item xs={12} md={4} spacing={2} className={classes.paper} >
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button>{options[selectedIndex].label}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                        onClickAway={handleTimeChange}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" className={classes.root}>
                                        {options.map((option, index) => {
                                            return <MenuItem
                                                key={option.value}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleDateBlockChange(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
            <Grid item container xs={12} md={4}>
                <Grid container xs={12} md={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                        <>
                            {dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item md={5} xs={6}><DatePicker value={initDate} minDate={userDateStart} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                            {dateblock === 'weekly' && <Grid item md={5} xs={6}><WeekPicker value={initDate} minDate={userDateStart} disableFuture={true} tr={t} onChange={handleDateStartChange} /></Grid>}
                            {dateblock === 'monthly' && <Grid item md={5} xs={6}><DatePicker value={initDate} minDate={userDateStart} views={["month", "year"]} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                            <Grid item xs={1}></Grid>
                            {dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item md={5} xs={6}>
                                <DatePicker value={endDate} minDate={initDate} disableFuture={true} onChange={handleDateEndChange} className={initDate.startOf('day').isSame(endDate.startOf('day')) ? 'inactiveInput' : null}/>
                            </Grid>}
                            {dateblock === 'weekly' && <Grid item md={5} xs={6}><WeekPicker value={endDate} minDate={initDate} disableFuture={true} tr={t} onChange={handleDateEndChange} /></Grid>}
                            {dateblock === 'monthly' && <Grid item md={5} xs={6}><DatePicker value={endDate} minDate={initDate} views={["month", "year"]} error={false} helperText={null} disableFuture={true} onChange={handleDateEndChange} className={initDate.startOf('month').isSame(endDate.startOf('month')) ? 'inactiveInput' : null}/></Grid>}
                        </>
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
            <Grid container item xs={12} md={4}>
                <FormLabel component="legend" className={classes.centerText}>{t('resolution')+':'}</FormLabel>
                <FormControl component="fieldset">
                    <RadioGroup xs={6} row aria-label="resolution" name="resolution" defaultValue="min" value={resolution} onChange={handleResolutionChange}>
                        {dateblock === 'daily' && <>
                            <FormControlLabel
                                value="hour"
                                control={<Radio color="primary" />}
                                label={t("Hora")}
                                labelPlacement="end"
                            /><FormControlLabel
                                value="day"
                                control={<Radio color="primary" />}
                                label={t("Diaria")}
                                labelPlacement="end"
                            />
                        </>}
                        {dateblock == 'monthly' && <>
                            <FormControlLabel
                                value="day"
                                control={<Radio color="primary" />}
                                label={t('Diaria')}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="month"
                                control={<Radio color="primary" />}
                                label={t("Mensual")}
                                labelPlacement="end"
                            />
                        </>}
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
        <RangedGrapics
            minLimit={minLimit}
            resolution={resolutionQuery}
            device={device}
            selectable={false}
            labels={units}
            route={apiRoute}
            dateStart={initDateQuery}
            dateEnd={endDateQuery}
        />
    </Grid>
}
