import React, { useState } from 'react'
import { Chart, Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import moment from 'moment';
import { useAuth } from '../utils/auth'
import { Grid, Box, useMediaQuery, useTheme, Typography } from '@material-ui/core'
import DateSelector from './DateSelector';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

export default function MixedGraphics(props) {

    let { device, route, minLimit } = props;
    const { tecnalia_client } = useAuth();
    //if (options === undefined) options = {};
    const [options, setOptions] = useState({})
    const [data, setData] = useState(false)
    const [tableData, setDataTable] = useState({})
    const { resolution, setResolution } = useState('day')
    const { t } = useTranslation(['consumption','date_selector']);
    const classes = useStyles();
    const width = useWidth();
    const isSmallScreen = /xs|sm/.test(width);

    const [isRequesting, setRequesting] = useState(false)
    const [hasErrors, setErrors] = useState(false)
    const [currentError, setCurrentError] = useState([])
    Chart.pluginService.register({
        afterDraw: function (chart) {
            // console.log('After draw: ', chart);
            // console.log('Title: ', chart.options.title.text);
            // console.log(chart.data.datasets.length, chart.canvas.id);
            if (chart.data.datasets.length === 0 || chart.data.datasets[0].data.length === 0) {
                // No data is present
                var ctx = chart.chart.ctx;
                var width = chart.chart.width;
                var height = chart.chart.height;
                chart.clear();

                ctx.save();
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.font = "18px normal 'Helvetica Nueue'";
                // chart.options.title.text <=== gets title from chart 
                // width / 2 <=== centers title on canvas 
                // 18 <=== aligns text 18 pixels from top, just like Chart.js 
                //ctx.fillText(t('chart_title'), width / 2, 18); // <====   ADDS TITLE
                ctx.fillText(t('no_data_to_display'), width / 2, height / 2);
                ctx.restore();
            }
        }
    })
    if (isSmallScreen) {
        options['maintainAspectRatio'] = false;
    } else {
        options['maintainAspectRatio'] = false;
        options['responsive'] = true;
    }
    const processRequest = async function (device, startdate, enddate, dateblock, resolution) {
        if (moment(startdate) < moment(minLimit)) startdate = minLimit;
        startdate = moment(startdate).format('YYYY-MM-DD')
        enddate = moment(enddate).format('YYYY-MM-DD')
        return await tecnalia_client(route, { device, startdate, enddate, dateblock, resolution }).then((response) => {
            if ('error' in response) {
                setCurrentError(response['error'])
                throw response
            }
            setData(response['graph'])
            response['table'].columns = response['table'].columns.map((col)=>{
                col.headerName = t(col.headerName)
                return col
            })
            setDataTable(response['table'])
            setOptions(response['options'])
            setRequesting(false)
        }).catch((error) => {
            console.error(error);

            setErrors(true)
            setRequesting(false)
        });
    }
const handleClick = (dateblock, resolution, startdate, enddate, ranged) => {

    setErrors(false)
    setRequesting(true)
    setCurrentError([])
    processRequest(device, startdate, enddate, dateblock, resolution)
}
return <Grid spacing={0} container>
    <Grid item container xs={12}><Typography>{t('service_description')}</Typography></Grid>
    <Grid item container xs={12}>
        <Grid item container xs={12}>
            <DateSelector handleClick={handleClick} userDateStart={minLimit} />
        </Grid>
        {isRequesting && <Grid item container xs={12} justify='center'><Typography><Box fontWeight='bold'>{t('waiting_response')}</Box></Typography></Grid>}
        {hasErrors && <Grid item container xs={12} justify='center'><Typography className={classes.errorField}><Box fontWeight='bold'>{t(currentError[0])} [{currentError[1]}]: {t(currentError[2])}</Box></Typography></Grid>}
        {!hasErrors && !isRequesting && data !== false && <><Grid item container xs={12}>
            <Box minHeight={isSmallScreen ? 300 : 500} width={'100%'}>
                <Bar data={data} options={options} />
            </Box>
        </Grid>
        <Grid item container xs={12}>
            <div style={{ height: 400, width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                        {tableData.rows && <DataGrid rows={tableData.rows} autoHeight columns={tableData.columns} pageSize={5} maxColumns={3}/>}
                    </div>
                </div>
            </div>
        </Grid>
        </>}
    </Grid>
</Grid>
}

