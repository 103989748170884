import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Link, useMediaQuery} from '@material-ui/core'
import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Button } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp';
import {useTranslation} from 'react-i18next'
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
const useStyles = makeStyles((theme)=>({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
      },
    link:{
      '&:hover': {
        textDecoration: "none",
      }
    }, linkIcon: { 
      '&:hover': {
        textDecoration: "none"
      }
    }, 
    iconButton:{
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(75,97,156,1);',
      '&:hover': {
        backgroundColor: 'rgba(135,146,177,1);',
      }
    }
}));
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}
export default function FraListItem(props){
    console.log(props);
    const { t } = useTranslation('facturation');
    const {id,type, primary, secondary, file, state} = props;
    const classes = useStyles();
    const width = useWidth();
    const isSmallScreen = /xs/.test(width);

    return <ListItem id={id}>
    <ListItemAvatar>
      <Avatar>
          {type === 'vivienda' ? <DescriptionIcon /> : <AssessmentIcon />}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary={primary}
      secondary={secondary.toUpperCase()}
    />
    <ListItemSecondaryAction>
    { isSmallScreen?
          <Link className={classes.linkIcon} href={"dexcell/bills/download/" + file + '?token=' + JSON.parse(localStorage.getItem('__auth_provider_token__')).token} download>
    <IconButton
      // variant="contained"
      // color='primary'
      className={classes.iconButton}
      //onClick={handleClick}
      disabled={state !== 'SUCCESS'}
    >
            
      {state !== 'SUCCESS' ? <ReportProblemIcon /> : <GetAppIcon />}
      </IconButton>
            </Link>
    :
          <Link className={classes.link} href={"dexcell/bills/download/" + file + '?token=' + JSON.parse(localStorage.getItem('__auth_provider_token__')).token} download>
    <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={state !== 'SUCCESS' ? <ReportProblemIcon /> : <GetAppIcon />}
        // onClick={handleClick}
        disabled={state !== 'SUCCESS'}
      >
            {t('download').toUpperCase()}
      </Button>
          </Link>
    }
    </ListItemSecondaryAction>
  </ListItem>
}