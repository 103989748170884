import React, { useEffect } from 'react'
import moment from 'moment';

import FraListItem from '../components/FraListItem'
import { Grid, Typography, List } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {useTranslation} from 'react-i18next'
import {useAuth} from '../utils/auth'
import { RestaurantRounded } from '@material-ui/icons';


export default function FraDownload(props){
    //const classes = useStyles();
    const {user,client} = useAuth();
    const user_id = user.id === 25285 ? 32781: user.id;//user.id;
    const mode = user.role;
    let {device, route } = props;
    if (device === undefined) device = 589878;
    if (route === undefined) route = 'cost/ranged';
    const [fraList, setFraList] = React.useState([]);
    const [elementList, setElementList] = React.useState([]);
    const [count, setCount] = React.useState(1);
    const [apiRoute, setApiRoute] = React.useState(route);
    const { t } = useTranslation('facturation');
    const processRequest = async function (user_id, mode){
        return await client(apiRoute,{user_id,mode}).then((response)=>{
            //parsear la respuesta
            
            let childs=[];
            if(response) {
                if (response.ok !== undefined && response.ok === false){
                    childs = [<Typography key={0} variant='h4' color='secondary'>{t(response.error)}</Typography>]
                    setCount(0);
                    return childs;
                }
                if (response.length === 0){
                    childs = [<Typography key={0} variant='h4' color='secondary'>{t('no_records')}</Typography>]
                    setCount(0);
                    return childs;
                }
                response.reverse().forEach((element,index) => {
                    console.log(element);
                    childs.push(<FraListItem type={element.report_type} secondary={moment(element.reference_date).locale(localStorage.getItem('i18nextLng')).format('MMMM YYYY')} primary={element.title} id={`fra-${index}`} state={element.status} file={element.id}/>)
                });
                setCount(Math.ceil(childs.length / 3));
                return childs;
            }

        })
    }

    const getPageItems = function(page, itemsPerPage=3){
        if(!fraList)
            return;
        if (fraList.length < itemsPerPage)
            return fraList
        let start = (page - 1) * itemsPerPage;
        let end = (start + itemsPerPage) > fraList.length ? fraList.length : (start + itemsPerPage)
        let output = fraList.slice(start, end);
        return output
    }
    const handleCounterChange = function(event,page){
        setElementList(getPageItems(page));
    }
    useEffect(()=>{
        processRequest(user_id, mode).then((parsedval)=>{
            setFraList(parsedval);
            
        })
    }, []);
    useEffect(()=>{
        setElementList(getPageItems(1));
    },[fraList])
    return <Grid item container xs={12}>
        <Grid item xs={12} container>
            <Grid item xs={12}>
                <Typography style={{textAlign: "center"}} variant='h4'>{t('check_download')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography style={{textAlign: "center", lineHeight: '1em'}} variant='h6'>{t('check_download_description')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {elementList}
                </List>
            </Grid>
            </Grid>
        {count > 0?
            <Grid item container justify='center' alignItems='center' xs={12}><Pagination count={count} size="small" onChange={handleCounterChange}/></Grid>
        :''}
        </Grid>
}