import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Home from '../parts/Home'
import Consumos from '../parts/Consumo'
import Facturation from '../parts/Facturation'
import Services from '../parts/Services'
import DHSection from '../parts/DhSection'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../utils/auth';
import Contact from '../parts/Contact';
import Recommendations from '../parts/Recommendations';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useCSS = makeStyles({
  responsiveText: {
    fontSize: 'calc(0.6em + 0.4vw)'
  },
  breakWord: {
    overflowWrap: 'anywhere'
  },
  highlighted: {
    backgroundColor: '#ffb733',
    //backgroundColor: '#8792b1',
    color: 'white'
  }
});

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function Tabbed({deviceid, locationid}) {
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const { utils_client, user } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRecommendations = async function () {
    return await utils_client('recommendations').then((response) => {
        var result = [];
        if(response.ok && response.lines != undefined) {
            result = response.lines;
        }
        return result;
    });
  }
  const isEmpty = function(lines) {
    var total = "";
    for(var line of lines) {
      total += line.trim();
    }
    return total.length == 0;
  }

  const [showRecommendations, setShowRecommendations] = React.useState(false)
  const [lines, setLines] = React.useState([])

  useEffect(() => {
    getRecommendations().then((result)=> {
      if(!isEmpty(result)) {
        setLines(result);
        setShowRecommendations(true);
      }
    });

    //if (user.id === 25285) setShowRecommendations(true)
  }, []);

  const styles = useCSS();
  
  return (
    <>
      <Paper>
        <Tabs
          key='my_current'
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          aria-label="seleccion"
          centered
        >
          <Tab label={t("tab_home")} className={styles.responsiveText} {...a11yProps(0)} />
          <Tab label={t("tab_billing")} className={styles.responsiveText} {...a11yProps(1)} />
          <Tab label={t("tab_contact")} className={styles.responsiveText + ' ' + styles.highlighted} {...a11yProps(2)} />
          {showRecommendations && <Tab label={t("tab_recommendation")} className={styles.responsiveText + ' ' + styles.breakWord} {...a11yProps(3)} />}
        </Tabs>
      </Paper>

        <TabPanel value={value} index={0}>
          <Home value={deviceid} location={locationid}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Facturation value={deviceid}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Contact/>
        </TabPanel>
        {showRecommendations && <TabPanel value={value} index={3}><Recommendations lines={lines}/></TabPanel>}
    </>
  );
}
