import React from 'react';
import {  Grid, Typography, Link } from '@material-ui/core';
import { useStyles } from '../utils/hooks';

import logo_footer from '../static/images/conjunto-logos-footer.svg';
import {useTranslation} from 'react-i18next'

export function Footer() {
  const classes = useStyles();
  const { t } = useTranslation();
  return <Grid container className={classes.ghostFooter}><Grid container className={classes.footer} style={{zIndex: '100'}}>
    <Grid container xs={12} style={{maxHeight: '1.8rem', margin: '0 0 0.4rem 0', justifyContent: 'center'}}>
      <img alt='' className={classes.adjust_image} src={logo_footer} />
    </Grid>
    <Grid container xs={12} className={classes.lowerBadge}>
      <Typography>{'©2023 Ferrovial construcción'}</Typography>
      <Typography style={{padding: '0 0.2rem 0 0.2rem'}}>{'|'}</Typography>
      <Typography style={{fontSize: '0.6rem', lineHeight: '0.8rem', color: 'white', textDecoration: 'underline'}} component={Link} href="/clausula">{t('data_protection')}</Typography>
      {/* <a href="/clausula" style={{color:'white'}}>{t('data_protection')}</a> */}
  </Grid>
  </Grid>
  </Grid>
}