import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import React, { PureComponent } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import { DatePicker } from "@material-ui/pickers";
import { createStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { IconButton, withStyles } from "@material-ui/core";
import moment, { Moment } from 'moment';
import { withTranslation } from "react-i18next";

export function stringToTestId(string) {
    return string.replace(/[&/\\#,+()$~%.'":*?<>{}\s]/g, '');
}

export function makeJSDateObject(date) {
    if (moment.isMoment(date)) {
        return (date).clone().toDate();
    }
    if (date instanceof Date) {
        return new Date(date.getTime());
    }

    return date; // handle case with invalid input
}

// function t(str,obj){
//     const { t, i18n } = useTranslation('date_selector');
//     return t(str, obj)
// }
class WeekPicker extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func
    }
    constructor(props) {
        super(props);
        //this.minDate = props.minDate;
        this.disableFuture = props.disableFuture;
       
        this.state = {
            selectedDate: props.value || moment(),
        }
        this.props.onChange({ start: moment(startOfWeek(makeJSDateObject(props.value), { weekStartsOn: 1 })), end: moment(endOfWeek(makeJSDateObject(props.value), { weekStartsOn: 1 })) })
    }


    handleWeekChange = date => {
        this.setState({ selectedDate: startOfWeek(makeJSDateObject(date),{weekStartsOn:1}) });
        this.props.onChange({ start: moment(startOfWeek(makeJSDateObject(date), { weekStartsOn: 1 })), end: moment(endOfWeek(makeJSDateObject(date), { weekStartsOn: 1 }))})
    };

    formatWeekSelectLabel = (date, invalidLabel) => {
        let dateClone = makeJSDateObject(date);

        return dateClone && isValid(dateClone)
            ? this.props.t('week_of', {'value': format(startOfWeek(dateClone, { weekStartsOn: 1 }), "dd MMM yyyy")})
            : invalidLabel;
    };

    renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
        const { classes } = this.props;
        let dateClone = makeJSDateObject(date);
        let selectedDateClone = makeJSDateObject(selectedDate);

        const start = startOfWeek(selectedDateClone, { weekStartsOn: 1 });
        const end = endOfWeek(selectedDateClone, { weekStartsOn: 1 });

        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    };

    render() {
        const { selectedDate } = this.state;

        return (
            <DatePicker
                label={this.props.t('week_selector')}
                value={selectedDate}
                onChange={this.handleWeekChange}
                renderDay={this.renderWrappedWeekDay}
                labelFunc={this.formatWeekSelectLabel}
                minDate={this.props.minDate}
                error={false}
                helperText={null}
                disableFuture={this.disableFuture}
            />
        );
    }
}

const styles = createStyles(theme => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
        color: "#676767",
    },
    highlight: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    },
}));

export default withTranslation('date_selector')(withStyles(styles)(WeekPicker));
