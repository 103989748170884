import React from 'react';
import {Button, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { useTranslation } from "react-i18next";
import {useAsync} from '../utils/hooks'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
//export default withStyles(styles)(LoginTab);
import {useAuth} from '../utils/auth'
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    envelop:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column'
    }, backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
export function Login(){
    const { t, i18n } = useTranslation('login');
    let lang = i18n.language;
    let currentLang = 'es';
    if (lang === 'es') currentLang = 'es_ES'
    else if (lang === 'en') currentLang = 'en_UK'
    const classes = useStyles();
    //const [loading, setLoading] = React.useState(false)
    const [state, setState] = React.useState({
        formData: {
            username: '',
            password: ''
        },
        submitted: false,
    })
    const { isLoading, isError, run} = useAsync()
    const do_auth = useAuth();
    function handleSubmit(event) {
        event.preventDefault()
        const {username, password} = event.target.elements
        run(
            do_auth.login({
                username: username.value,
                password: password.value,
            }),
        )
    }
    function handleChange(event) {
        const { formData } = state;
        formData[event.target.name] = event.target.value;
        setState({formData});
    }
    return (<>
        <ValidatorForm className={classes.envelop} onSubmit={handleSubmit}>
            <Grid item xs={12}>
                <Typography variant='h3'>{t('access_title')}</Typography>
            </Grid>
            <Grid item xs={12}>
            <TextValidator
                label={t('user')}
                onChange={handleChange}
                name="username"
                value={state.formData.username}
                validators={['required', 'isEmail']}
                placeholder={t('user_placeholder')}
                errorMessages={[t('field_required'), t('invalid_mail')]}
            />
            </Grid>
            <Grid item xs={12}>
            <br />
            </Grid>
            <Grid item xs={12}>
            <TextValidator
                label="Password"
                onChange={handleChange}
                name="password"
                value={state.formData.password}
                validators={['required']}
                errorMessages={[t('field_required')]}
                placeholder={t('passowrd_placeholder')}
                type='password'
            />
            </Grid>
            <Grid item xs={12}>
            <br />
            </Grid>
            <Grid item xs={12}>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                //disabled={data.username && data.password}
                
            >
            {t('login_button')}
            </Button>
            </Grid>
            <Grid item xs={12}>
                <br />
            </Grid>
            {/* <Grid item xs={12} justify='flex-end' container>
                <Link variant='subtitle2' href={'https://ems.districtheatingtxomin.eus/security/recover/forgot.htm?siteLanguage='+currentLang}>{t('recover_pass')}</Link>
            </Grid> */}
            {
                isError ? <Alert severity="error" variant="filled">{t('login_error')}</Alert> : ""
            }
        </ValidatorForm>
    
        {
            isLoading && <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        }
    </>
    )
}

