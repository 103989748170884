import React from 'react';
import {Header} from '../components/header'
import {Footer} from '../components/footer'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import {useTranslation} from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    
}));

export function DataProtection({ value }) {
    const { i18n } = useTranslation();
    const handleLanguageChange = function (lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <Grid container >
              <Grid item xs={12} container><Header onLangChange={handleLanguageChange} clausula={true}/></Grid>
              <Grid container alignItems='center' justify='center'>
                <h1>CLÁUSULA DE PRIVACIDAD</h1>
                <Typography style={{padding: '2em'}}>De conformidad con el Reglamento (UE) 2016/679 de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, y la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, la EMVS, como responsable del tratamiento de sus datos personales le informa que la finalidad para la que estos son recabados es registrar los consumos y registrar y gestionar las incidencias notificadas a través del presente canal, siendo la base legitimadora del tratamiento es la ejecución de un contrato en el que el interesado es parte.
                <br/>
                Asimismo, le informamos que Ferrovial podrá tener acceso a sus datos, en calidad de encargado del tratamiento. 
                Ferrovial no tratará sus datos personales para fines propios de ningún tipo, sino únicamente accederá a los mismos en nombre y por cuenta de la EMVS, para la finalidad encomendada por la empresa municipal.
                <br/>
                La EMVS podrá ceder sus datos a las administraciones, organismos públicos y terceros competentes para la gestión del tratamiento. 
                <br/>
                No se realizarán transferencias internacionales, ni se adoptarán decisiones basadas únicamente en el tratamiento automatizado de los datos personales de sus usuarios.
                <br/>
                Los datos personales solicitados para esta actividad serán conservados el tiempo necesario para cumplir con la finalidad informada. Una vez satisfecha la referida finalidad, sus datos serán conservados atendiendo a los plazos establecidos en la normativa sectorial aplicable.
                En cualquier momento, el titular puede ejercer sus derechos de acceso, rectificación, supresión, limitación, portabilidad, u oposición, personándose en nuestras oficinas, o dirigiéndose por escrito a la EMVS, ya sea por correo postal o a través de e-mail:  proteccion.datos@emvs.es. En todos los casos debe aportar documento acreditativo de su identidad.  
                Puede contactar con el Delegado de Protección de Datos a través del dpd@emvs.es, y reclamar ante la Agencia Española de Protección de datos, en caso de que no obtenga la respuesta pretendida.
                </Typography>
            </Grid>
            <Grid item xs={12} container><Footer /></Grid>
          </Grid>
        </>
    )
}