import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import 'moment/locale/eu'
//import 'moment/locale/en'
import MomentUtils from '@date-io/moment';
import { Button, ButtonGroup, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, makeStyles, MenuItem, MenuList, Popper, Radio, RadioGroup, Switch, Typography } from '@material-ui/core'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import WeekPicker from '../pickers/WeekPicker'
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import endOfWeek from "date-fns/endOfWeek";
import startOfWeek from "date-fns/startOfWeek";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    }, paper: {
        zIndex: 2
    }, spacer: {
        padding: '4px 0'
    }
}));

export default function ServicePicker(props) {
    let minLimit;
    const classes = useStyles();
    const { userDateStart, handleClick } = props;
    const [resolution, setResolution] = useState('min')
    const [dateblock, setDateBlock] = useState('daily')
    const [initDate, setInitDate] = useState(moment(new Date()));
    const [endDate, setEndDate] = useState(moment(new Date()));
    const [ranged, setRanged] = React.useState(false);
    const { t, i18n } = useTranslation('date_selector');
    const options = [
        { label: t('daily'), value: 'daily' },
        { label: t('monthly'), value: 'monthly' }

    ]
    let defIndex = 0;
    const [selectedIndex, setSelectedIndex] = React.useState(defIndex);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    moment.locale(i18n.language)
    if (minLimit !== undefined && (initDate === undefined || moment(initDate) <= moment(minLimit))) {
        initDate = minLimit
    };
    const handleDateStartChange = (value) => {
        //console.log('handleDateStartChange',value)
        if (dateblock === 'monthly') {
            setInitDate(value.clone().startOf('month'))
            if (!ranged) {
                setEndDate(value.clone().endOf('month'))
            }

        } else if (dateblock === 'weekly') {
            setInitDate(value['start'].clone().startOf('day'))
            if (!ranged) {
                setEndDate(value['end'].clone().endOf('day'))
            }
        }
        else {
            setInitDate(value.clone().startOf('day'))
            if (!ranged) {
                setEndDate(value.clone().endOf('day'))
            }
        }

    }
    const handleDateEndChange = (value) => {
        console.log('handleDateEndChange', value)
        if (dateblock === 'weekly') {
            if (ranged) {
                setEndDate(value['end'].clone().endOf('day'))
            }
        } else {
            setEndDate(value.clone().endOf('day'))
        }
    }
    const handleDateBlockChange = (event, index) => {
        setDateBlock(options[index].value)
        setSelectedIndex(index);
        setOpen(false);
        if (options[index].value === 'daily' && !ranged)
            setResolution('min')
        else
            setResolution('day')
    }
    const handleRangedChange = (event) => {
        setRanged(event.target.checked);
        if (event.target.checked) setEndDate(moment())
    };
    const makeJSDateObject = function (date) {
        if (moment.isMoment(date)) {
            return (date).clone().toDate();
        }
        if (date instanceof Date) {
            return new Date(date.getTime());
        }

        return date; // handle case with invalid input
    }
    // Los cambios son asíncronos, así que hay que capturarlos por separado
    useEffect(() => {
        if (dateblock === 'weekly') handleDateStartChange({ start: moment(startOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })), end: moment(endOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })) })
        else handleDateStartChange(initDate)
    }, [dateblock])
    useEffect(() => {
        if (!ranged) {
            if (dateblock === 'daily' || dateblock === 'monthly') handleDateStartChange(initDate)
            else if (dateblock === 'weekly') {
                setEndDate(moment(endOfWeek(makeJSDateObject(initDate), { weekStartsOn: 1 })))
            }
            if (dateblock === 'daily'){
                setResolution('hour')
            } else{
                setResolution('day')
            }
        }
        else if (ranged) {
            if (dateblock === 'weekly') handleDateEndChange({ 'end': moment() })
            else handleDateEndChange(moment())
            if (dateblock === 'daily'){
                setResolution('hour')
            }
            else {
                setResolution('month')
            }
        }
    }, [ranged])
    const handleResolutionChange = (event) => {
        setResolution(event.target.value)
    }

    const handleSelectorClick = () => {
        handleClick(dateblock, resolution, initDate, endDate)
    }
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);

    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return <Grid container>
        <Grid container item xs={12} className={classes.paper}>
            <Grid container item xs={12} className={classes.spacer}>
                <FormLabel component="legend">{t('select_query_type')}</FormLabel>
            </Grid>
            <Grid container item xs={12} className={classes.spacer}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                    <Button>{options[selectedIndex].label}</Button>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" className={classes.root}>
                                        {options.map((option, index) => {
                                            return <MenuItem
                                                key={option.value}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleDateBlockChange(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>

        <Grid container item xs={12} >
            <FormControl component="fieldset" fullWidth={true}>
                <Grid container item xs={12} className={classes.spacer}><FormLabel component="legend">{t('select_date_or_dates')}</FormLabel></Grid>
                <Grid container item xs={12} className={classes.spacer}><FormControlLabel
                    control={<Switch checked={ranged} onChange={handleRangedChange} name="ranged" />}
                    label={t("range")}
                /></Grid>
                <Grid container item xs={12} className={classes.spacer}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                        <>
                            {dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item xs={ranged ? 5 : 12}><DatePicker value={initDate} minDate={userDateStart} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                            {dateblock === 'weekly' && <Grid item xs={ranged ? 5 : 12}><WeekPicker value={initDate} minDate={userDateStart} disableFuture={true} tr={t} onChange={handleDateStartChange} /></Grid>}
                            {dateblock === 'monthly' && <Grid item xs={ranged ? 5 : 12}><DatePicker value={initDate} minDate={userDateStart} views={["month", "year"]} disableFuture={true} onChange={handleDateStartChange} /></Grid>}
                            {ranged && <Grid container item xs={2}></Grid>}
                            {ranged && dateblock !== 'weekly' && dateblock !== 'monthly' && <Grid item xs={5}><DatePicker value={endDate} minDate={initDate} disableFuture={true} onChange={handleDateEndChange} /></Grid>}
                            {ranged && dateblock === 'weekly' && <Grid item xs={5}><WeekPicker value={endDate} minDate={initDate} disableFuture={true} tr={t} onChange={handleDateEndChange} /></Grid>}
                            {ranged && dateblock === 'monthly' && <Grid item xs={5}><DatePicker value={endDate} minDate={initDate} views={["month", "year"]} disableFuture={true} onChange={handleDateEndChange} /></Grid>}
                        </>
                    </MuiPickersUtilsProvider>
                </Grid>
            </FormControl>
        </Grid>
        <Grid container item xs={12} className={classes.spacer}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{t('resolution')}</FormLabel>
                <RadioGroup row aria-label="resolution" name="resolution" defaultValue="min" value={resolution} onChange={handleResolutionChange}>
                    {!ranged && dateblock === 'daily' && <>
                        <FormControlLabel
                            value="min"
                            control={<Radio color="primary" />}
                            label={t("5min")}
                            labelPlacement="top"
                        /><FormControlLabel
                            value="hour"
                            control={<Radio color="primary" />}
                            label={t("Hora")}
                            labelPlacement="top"
                        />
                    </>}
                    {ranged && dateblock == 'daily' && <>
                        <FormControlLabel
                            value="hour"
                            control={<Radio color="primary" />}
                            label={t('Hora')}
                            labelPlacement="top"
                        />
                        <FormControlLabel
                            value="day"
                            control={<Radio color="primary" />}
                            label={t("Diario")}
                            labelPlacement="top"
                        />
                    </>}
                    {!ranged && dateblock === 'monthly' && <Typography>{t("Diario")}</Typography>}
                    {ranged && dateblock === 'monthly' && <Typography>{t("Mensual")}</Typography>}

                </RadioGroup>
            </FormControl>
        </Grid>
        <Grid container item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSelectorClick}>{t('submit')}</Button>
        </Grid>
    </Grid>
}
