import React, { useEffect } from 'react'
import { Typography, Grid, makeStyles } from '@material-ui/core'
import InfoBadge from '../components/InfoBadge';
import moment from 'moment';
import {useAuth} from '../utils/auth'
import {useTranslation} from 'react-i18next'

const useCSS = makeStyles({
    inputRoot: {
        fontSize: 30
    },
    label: {
        fontSize: '0.9rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        lineHeight: '1rem'
    },
    subtitle: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        lineHeight: '1.2rem'
    }
    , title: {
        fontSize: '1.8em',
        fontWeight: 'bold',
    },
    vspacer: {
        minHeight: '1rem'
    },
    rounded: {
        borderRadius: 15
    },
    flex_fixed: {
        "& div": {
            flexBasis: "unset"
        }
    }
});

const prev_month = moment().subtract(1, 'months').format('YYYY-MM');

const current_month = moment().format('YYYY-MM');
const current_month_name = moment().format('MMMM');

const prev_year = moment().startOf('year').subtract(1, 'year').format('YYYY-MM');

const current_year = moment().format('YYYY');
const current_year_start = moment().startOf('year').format('YYYY-MM');

export default function MiConsumoResume(props) {
    const { t } = useTranslation(['consumption','time']);
    const {client} = useAuth();
    let {device, param, units} = props;

    const apiRoute = 'readings/' + param;

    const styles = useCSS();
    const year = current_year;

    const [currentMonthConsumption, setCurrentMonthConsumption] = React.useState('0.0');
    const [currentYearConsumption, setCurrentYearConsumption] = React.useState('0.0');

    const [currentMonthComparison, setCurrentMonthComparison] = React.useState('N/A');
    const [currentYearComparison, setCurrentYearComparison] = React.useState('N/A');

    let currentMonthValue = 0.0;
    let currentYearValue = 0.0;

    useEffect(()=>{
        if (device !== ''){
            processRequest(device, current_month, current_month, 'month','M');
            processRequest(device, current_year_start, current_month, 'year','M');
        }
    }, [device, param])

    const processRequest = async function(device,startdate,enddate,type,resolution){
        return await client(apiRoute, {device,startdate,enddate,resolution}).then((response)=>{
            //parsear la respuesta
            let value = 0.0;
            if (response['datasets'].length > 0 && response['datasets'][0]['data'].length > 0){

                for(var item of response['datasets'][0]['data']) {
                    value += item;
                }
                value = value.toFixed(1);
            }
            return value;
        }).then((parsedval)=>{
            if(type === 'month') {
                setCurrentMonthConsumption(parsedval);
                currentMonthValue = parseFloat(parsedval);
                processRequest(device, prev_month, prev_month, 'lastMonth', 'M');
            }
            else if(type === 'year') {
                setCurrentYearConsumption(parsedval);
                currentYearValue = parseFloat(parsedval);
                processRequest(device, prev_year, current_year_start, 'lastYear', 'M');
            }
            else if(type === 'lastMonth') {
                let percentage = 0.0;
                if (currentMonthValue !== 0)
                    percentage = (parseFloat(parsedval) / currentMonthValue);
                let sign = '+'
                if(percentage < 0)
                    sign = '-';
                if(percentage >= 1)
                    percentage = parseInt(percentage);
                else
                    percentage = (percentage * 100).toFixed(2);
                
                setCurrentMonthComparison(sign + percentage + "%");
            }
            else if(type === 'lastYear') {
                let percentage = 0.0;
                if (currentYearValue !== 0) 
                    percentage = (parseFloat(parsedval) / currentYearValue);
                let sign = '+'
                if(percentage < 0)
                    sign = '-';
                    if(percentage >= 1)
                    percentage = parseInt(percentage);
                else
                    percentage = (percentage * 100).toFixed(2);
                
                setCurrentYearComparison(sign + percentage + "%");
            }
        }).catch((error)=>{return});
    }
    
    return <Grid container className={styles.rounded, styles.flex_fixed}>
        <Grid item xs={12}>
            <Typography className={styles.title}>{t('my_current_usage')}</Typography>
        </Grid>
        <Grid item container xs={12} className={styles.vspacer}></Grid>
        <Grid container item xs={6} justify='center' alignItems='center' direction='column'>
            <Grid item container xs={12}>
                <Grid item xs={12}><Typography className={styles.label}>{t('month_usage')}</Typography></Grid>
                <Grid item container xs={12}><Typography className={styles.subtitle}>{t('time:'+current_month_name.toLowerCase())}</Typography></Grid>
            </Grid>
            <Grid item container xs={12} className={styles.vspacer}></Grid>
            <Grid item container xs={12}>
                <Grid item><InfoBadge value={currentMonthConsumption} units={units} badgeSize='large' /></Grid>
                <Grid item><InfoBadge value={currentMonthComparison} comment={t('month_consumption')} /></Grid>
            </Grid>
        </Grid>
        <Grid container item xs={6} justify='center' alignItems='center' direction='column'>
            <Grid item container xs={12}>
                <Grid item xs={12}><Typography className={styles.label}>{t('year_usage')}</Typography></Grid>
                <Grid item container xs={12}><Typography className={styles.subtitle}>{year}</Typography></Grid>
            </Grid>
            <Grid item container xs={12} className={styles.vspacer}></Grid>
            <Grid item container xs={12}>
                <Grid item><InfoBadge value={currentYearConsumption} units={units} badgeSize='large' /></Grid>
                <Grid item><InfoBadge value={currentYearComparison} comment={t('year_consumption')} /></Grid>
            </Grid>
        </Grid>
    </Grid>
}
