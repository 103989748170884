import React from 'react'
import {Header} from '../components/header'
import {Content} from '../components/content'
import {Footer} from '../components/footer'
import { Grid } from '@material-ui/core'
import {useTranslation} from 'react-i18next'

export function LandingPage({value}) {
  const { i18n } = useTranslation();
  const handleLanguageChange = function (lang) {
    i18n.changeLanguage(lang);
  }
    return (
        <>
        <Grid container >
          <Grid item xs={12} container><Header onLangChange={handleLanguageChange}/></Grid>
            <Grid item xs={12} container><Content /></Grid>
            <Grid item xs={12} container><Footer /></Grid>
        </Grid>
      </>
    )
  }