import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './auth';

const LogoutPopup = ({onTimeExpires, onIdle, expireTime, warnTime}) => {
    const user = useAuth();
    const [signoutTime, setSignoutTime] = useState(expireTime * 1000);
    const [warningTime, setWarningTime] = useState(warnTime * 1000);
    const [logout, setLogout] = useState(false)
    let warnTimeout = useRef(null);
    let logoutTimeout = useRef(null);
    const [open, setOpen] = React.useState(true);
    const [t] = useTranslation();

    const handleClose = () => {
        setOpen(false);
        onTimeExpires();
    };
    useEffect(() => {
        const events = [
            
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];
        
        const warn = () => {
            console.warn('Session will be expired soon');
        };
        const do_logout = () => {
            if (onTimeExpires) {
                setLogout(true);       
            }

        }
        const setTimeouts = () => {
            warnTimeout.current = setTimeout(warn, warningTime);
            logoutTimeout.current = setTimeout(do_logout, signoutTime);
        };

        const clearTimeouts = () => {
            if (warnTimeout) clearTimeout(warnTimeout.current);
            if (logoutTimeout) clearTimeout(logoutTimeout.current);
        };
        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
            onIdle();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }
        if (user === null) {
            clearTimeouts();
            return
        } else {
            setTimeouts();
        }
        
        return () => {
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    }, []);


    return <>
        {logout && <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{t('expired_session_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>{t('expired_session_msg')}</Typography>
            </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('ok')}
                </Button>
            </DialogActions>
            </Dialog> }
        </>
}
export default LogoutPopup;