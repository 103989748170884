import React from 'react';
import { ListItem, Toolbar, Tabs } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next'

export function Forecast({ forecast, tempClass }){
	const { t } = useTranslation('time');
	let index = 0;
	return <div className={`forecast ${`${tempClass}-forecast`}`}>
		<div className="horiz-menu">
			<Toolbar>
				<Tabs variant='scrollable' scrollButtons="auto" className="forecast-area" value={false} selectionFollowsFocus={false}>
					{forecast.map((day) => {
						index+=1;
						let dayNight = '';
						if (
							parseInt(moment(day.dt_txt).format('HH')) <= 6 ||
							parseInt(moment(day.dt_txt).format('HH')) >= 19
						) {
							dayNight = 'night';
						} else {
							dayNight = 'day';
						}
						return (
							<ListItem key={index} className="forecast-cell">
								<h5>{t(moment(day.dt_txt).locale('en').format('dddd').toLowerCase())}, {moment(day.dt_txt).format('HH:mm')}</h5>
								{dayNight === 'night' ? (
									<i className={`wi wi-owm-night-${day.weather[0].id}`} />
								) : (
									<i className={`wi wi-owm-${day.weather[0].id}`} />
								)}
								<p>
									<strong className="temp-text">{Math.round(day.main.temp)}&deg;</strong>
								</p>
							</ListItem>
						);
					})}
				</Tabs>
			</Toolbar>
		</div>
	</div>
}
