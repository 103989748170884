import React from 'react';
// import Modal from '@material-ui/core/Modal';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next'
import { CardMedia } from '@material-ui/core';

// import es from '../static/guide/dh_guia_es.mp4';
// import en from '../static/guide/dh_guia_en.mp4';
// import eu from '../static/guide/dh_guia_eu.mp4';
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#ffa600d3',
        color: 'white',
    },
}));

export default function Guide({onClose}) {
    const [open, setOpen] = React.useState(true);
    const { t, i18n} = useTranslation();
    const classes = useStyles();
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('md');
    const guide = {
        // 'es': es,
        // 'en': en,
        // 'eu': eu
    }
    const handleClose = () => {
        setOpen(false);
        if (onClose) {onClose()}
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{t('user_guide')}</DialogTitle>
                <CardMedia
                    component='video'
                    className={classes.media}
                    image={guide[i18n.language]}
                    autoPlay
                />
            </Dialog>
        </div>
    );
}