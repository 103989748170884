import React from 'react'
import {Login} from './Login'
import {useAuth} from '../utils/auth'
import {Container, Grid} from '@material-ui/core'
import Tabbed from './Tabbed'


export function Content({value, location}) {
    const {user} = useAuth();
    if (user === null || user === false)
        return <Container maxWidth={false}>
            <Grid container alignItems='center' style={{height: '1rem'}}/>
            <Grid container alignItems='center' justify='center'>
                <Grid item container xs={6} justify='center'><Login/></Grid>
            </Grid>
        </Container>
    return <Container style={{backgroundColor: 'rgb( 228, 245, 251)'}} maxWidth={false}>
        <Grid container alignItems='center' justify='center'>
            <Grid item xs={12}>
                <Tabbed deviceid={value} locationid={location}></Tabbed>
            </Grid>
        </Grid>
    </Container>
  }