import React from 'react'
import {useAuth, AuthProvider} from './utils/auth'
import {LandingPage} from './parts/LandingPage'
import {UserPage} from './parts/UserPage'
import {DataProtection} from './parts/DataProtection'
import { ThemeProvider,createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors'
import './App.css';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment-timezone'

import i18n from "./utils/i18n";

function Home() {
  const [route, setRoute] = React.useState(window.location.pathname);
  const {user} = useAuth();
  console.log("ROUTE: " + route);
  if (route === '/') {
    return user ? <UserPage /> : <LandingPage />
  } else if (route === '/clausula') {
    return <DataProtection />
  }
}
// let theme = createMuiTheme({
//   palette:{
//     primary:orange,

//     text:{
//       secondary:orange[500]
//     }
//   }
// });
let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8792b1',
      main: '#4b619c',
      dark: '#274594',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e5eaef',
      main: '#e3f4fa',
      dark: '#a8cdf0',
      contrastText: '#000',
    },
  },
});
theme = responsiveFontSizes(theme);
function App() {
  moment.tz.setDefault('Europe/Madrid');
  return (
    
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n} fallback='eu'>
        <AuthProvider>
          <Home/>
        </AuthProvider>
        </I18nextProvider>
    </ThemeProvider>
    
  )
}

export default App
