import React, { useEffect } from 'react'
import {Bar} from 'react-chartjs-2'
import moment from 'moment'
import 'moment/locale/es'
import MomentUtils from '@date-io/moment';
import {makeStyles} from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import {useAuth} from '../utils/auth'
import { Grid, Typography, TextField, Box, useMediaQuery, useTheme } from '@material-ui/core'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
const useStyles = makeStyles((theme)=>({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      }
}));

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}
const default_end = moment().format('YYYY-MM');

export default function RangedGrapics(props){
    const {client} = useAuth();
    let {labels, device,dateStart,dateEnd, route, selectable, resolution, minLimit } = props;
    //if (device === undefined) device = 0; //589878;
    //if (route === undefined) route = 'cost/ranged';
    if (dateEnd === undefined) dateEnd = default_end;
    if (selectable === undefined) selectable = true;
    if (resolution === undefined) resolution = 'M';
    if (minLimit !== undefined && (dateStart === undefined || moment(dateStart) <= moment(minLimit))) {
        dateStart = minLimit
    };
    const classes = useStyles();
    const width = useWidth();
    const isSmallScreen = /xs|sm/.test(width);
    //const [apiRoute, setApiRoute] = React.useState(route);
    const apiRoute = route;
    const res = resolution;
    const [startDate, setStartDate] = React.useState(dateStart);
    const [endDate, setEndDate] = React.useState(dateEnd);
    const [rangedBar, setRangedBar] = React.useState({});
    //const [res, setRes] = React.useState(resolution);
    const { t, i18n } = useTranslation('date_selector');
    
    useEffect(()=>{
        //console.log("Updating RangedGrapics", apiRoute, device, startDate, endDate, labels, res);
        if (device !== '')
            processRequest(device, dateStart, dateEnd, labels, res);
    }, [route,device,dateStart,dateEnd,resolution])

    //console.log("RangedGraphics!!", route);

    const handleStartChange = (event) => {
        // setStartDate(event.target.value);
        setStartDate(moment(new Date(event.format("YYYY-MM-01"))).format("YYYY-MM-01"))
        //processRequest(device, event.target.value, endDate)
        processRequest(device, event.format("YYYY-MM-01"), endDate)
      };
      const handleEndChange = (event) => {
        // setEndDate(event.target.value);
        // processRequest(device, startDate, event.target.value)
          let lastDay = moment(new Date(event.format("YYYY-MM-") + event.daysInMonth())).format("YYYY-MM-DD")
          setEndDate(lastDay);
          processRequest(device, startDate, lastDay)
      };
      const processRequest = async function(device,startdate,enddate,labels,res){
          if (moment(startdate) < moment(minLimit)) startdate = minLimit;
          var resolution = res;
          //console.log({device,startdate,enddate,resolution});
          return await client(apiRoute,{device,startdate,enddate,resolution}).then((response)=>{
              setRangedBar(response);
            });
    }
    let minLimitFormat = moment(minLimit).format('YYYY-MM');
    return <Grid spacing={0} container>
        <Grid item container xs={12}>
            <Box minHeight={ isSmallScreen?300:500} width={'100%'}>
                <Bar data={rangedBar} options={isSmallScreen ? { 'maintainAspectRatio': false } : { 'maintainAspectRatio': false,'responsive': true }}/>
            </Box>
        </Grid>
        {selectable?
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography id="range-slider" gutterBottom>
                {t('date_selection')}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* <TextField
                    id="startmonth"
                    label={t('start_date')}
                    type="month"
                    defaultValue={moment(startDate).format('YYYY-MM')}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                        InputProps={{ inputProps: { min: minLimitFormat, max: moment(endDate).format('YYYY-MM') } }}
                    onChange={handleStartChange}
                /> */}
                <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                        <DatePicker value={startDate} minDate={minLimitFormat} maxDate={endDate} views={["year", "month"]} floatingLabelText="Date" disableFuture={true} onChange={handleStartChange} />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* <TextField
                    id="endmonth"
                    label={t('end_date')}
                    type="month"
                    defaultValue={endDate}
                    className={classes.textField}
                    InputLabelProps={{
                    shrink: true,
                    }}

                    InputProps={{inputProps: { min: moment(startDate).format('YYYY-MM'), max: moment().format('YYYY-MM') }}}
                    onChange={handleEndChange}
                /> */}
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
                        <DatePicker value={Math.min(new Date(endDate),new Date())} maxDate={new Date()} minDate={startDate} views={["year", "month"]} floatingLabelText="Date" disableFuture={true} onChange={handleEndChange} />
                    </MuiPickersUtilsProvider>
            </Grid>
        </Grid>
        :''}
    </Grid>
}

