import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import { useAuth } from '../utils/auth';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: '#ffa600d3',
        color: 'white',
    },
}));

export default function Recommendations() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { utils_client, user } = useAuth();
    const [paragraphs, setParagraphs] = React.useState([]);

    // var filedata = "";

    // const [open, setOpen] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    //     filedata = "";
    // };

    // const [isValidUser, setValidUser] = React.useState(false);

    // const checkUser = async function () {
    //     return await utils_client('isvalidusertoupload').then((response) => {
    //         return response.valid_user || false;
    //     });
    // }

    const getRecommendations = async function () {
        return await utils_client('recommendations').then((response) => {
            var result = [];
            if(response.ok && response.lines != undefined) {
                for(var line of response.lines) {
                    result.push([<Grid container xs={12} style={{marginTop: '3rem'}}><Typography align="left">{line}</Typography></Grid>]);
                }
            }
            return result;
        });
    }

    // const uploadFile = async function (data) {        
    //     return await utils_client('uploadrecommendation', {data}).then((response) => {
    //         var result = [];
    //         if(response.ok) {
    //             console.log("OK!");
    //         }
    //         return result;
    //     });
    // }
    // const handleCapture = function (event) {
    //     const reader = new FileReader();
    //     reader.onload = (e) => {
    //         filedata = e.target.result;
    //     }
    //     var file = event.target.files[0];
    //     reader.readAsDataURL(file);
    // }
    // const handleUpload = () => {
    //     uploadFile(filedata);
    //     handleClose();
    // }

    useEffect(()=>{
        getRecommendations().then((lines)=>{
            setParagraphs(lines);
        });

        // checkUser().then((result)=>{
        //     setValidUser(result);
        // });
    }, []);

    return (
        <Grid container alignItems='center' justify='center' xs={12}>
            {/* {isValidUser && (
                <Grid container alignItems='center' justify='center' xs={12} md={8}>
                    <Button 
                        variant="contained"
                        color="primary"
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleClickOpen}>
                            Subir nuevo fichero de texto
                    </Button>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Subir fichero de texto</DialogTitle>
                        <DialogContent>
                        <DialogContentText>
                            Seleccione un fichero de texto con una línea por cada párrafo a mostrar o vacío si quiere ocultar la pestaña
                        </DialogContentText>
                        <Button variant="contained" component="label">
                            Cargar fichero
                            <input
                                type="file"
                                accept=".txt"
                                hidden
                                onChange={handleCapture}
                            />
                        </Button>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleUpload} color="primary">
                            Subir
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            )} */}
            <Grid container alignItems='center' justify='center' xs={12} md={8}>
                {paragraphs}
            </Grid>
        </Grid>
    );
}