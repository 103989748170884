import { Button, ButtonGroup, Grid, MenuItem, MenuList, Popper } from '@material-ui/core'
import React, { useEffect } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next'

export default function LanguageSelector(props) {
    const { t, i18n } = useTranslation();
    const options = [
        { lang: t('lang_es'), value: 'es' },
        { lang: t('lang_en'), value: 'en' }
        
    ]
    const getLanguage = () => {
        return i18n.language ||
            (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
            'eu';
    };
    console.log(props);
    let currentLang = getLanguage();
    //moment.locale(currentLang);
    let defIndex = 0;
    // if (currentLang.indexOf('es') !== -1) defIndex = 1;
    // else if (currentLang === 'en') defIndex = 1;
    // else if (currentLang === 'eu') defIndex = 0;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(defIndex);
    useEffect(() => {
        props.onLangChange(options[defIndex].value)
    }, [])

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        props.onLangChange(options[index].value)
        //moment.locale(options[index].value);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return <Grid item container justify='flex-end' alignItems='flex-end' sm={4} xs={5}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button>{options[selectedIndex].lang}</Button>
            <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                {options.map((option, index) => {
                                    return <MenuItem
                                        key={option.value}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option.lang}
                                    </MenuItem>
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </Grid>
}