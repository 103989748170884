import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';
import translationEng from "../locale/en/translation.json";
import translationSpa from "../locale/es/translation.json";
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "es", // use es if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection:{
            lookupLocalStorage: 'i18nextLng',
            order: ['localStorage'],
            caches: ['localStorage']
        }
        ,
        resources: {
            en: translationEng,
            es: translationSpa
        },
        // have a common namespace used around the full app
        ns: ["translations","login"],
        defaultNS: "translations",
        react: {
            wait: true,
            useSuspense: false,
        }
    });

export default i18n;