import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from './auth';

const ErrorPopup = ({onEnd, title, msg}) => {
    const user = useAuth();
    const [open, setOpen] = React.useState(true);
    const [t] = useTranslation()

    const handleClose = () => {
        setOpen(false);
        onEnd();
    };

    return <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography>{msg}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
}
export default ErrorPopup;