import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import MiConsumoResume from './MiConsumoResume';
import {Forecast} from '../components/Forecast';
import SubTabbed from '../components/SubTabbed';
import { makeStyles } from '@material-ui/core/styles';
//import forecast from '../assets/forecasts';
import RangedGrapics from '../components/RangedGraphics';
import {useTranslation} from 'react-i18next';
import { useAuth, getStartContractDate } from '../utils/auth';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles({
  mycurrentconsumption: {
      background: 'white',
      borderRadius: '1rem',
      height: '100%'
  },
  muiTypographyH6Important: {
        fontSize: "1.3rem !important",
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: "500 !important",
        lineHeight: "1.6 !important",
        letterSpacing: "0.0075em !important",
        padding: "0 !important",

        "& .MuiInputBase-input": {
            padding: "0 24px 0 0 !important"
        }
  },
  verticalSpacer: {
      paddingBottom: '1rem !important'
  },
  vCentered: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  rangeSpacer: {
    padding: '18px 8px 12px 8px !important'
  }
});

export default function Home({value, location}){
    const classes = useStyles();
    const { t } = useTranslation('consumption');
    const { client, user } = useAuth();
    const [forecast,setForecast] = React.useState([])
    const [param, setParam] = React.useState("THERMENERGY");

    const [units, setUnits] = React.useState('kWH');
    
    const handleSelectionChange = (event) => {
        setUnits(event.currentTarget.getAttribute('units'));
        setParam(event.target.value);
    };
    
    const processRequest = async function (address) {
        return await client('forecast', { address }).then((response) => {

            if (response !== undefined && response.ok !== undefined && response.ok === true)
                setForecast(response.list)
    })}
    useEffect(() => {
        const address = 'Madrid,ES'
        if (user)
            processRequest(address)
    }, []);

    var devices = [];
    for(var loc of user.access_policy.locations) {
        if(loc.id == location) {
            for(var ref of loc.reference_devices) {
                if(ref.type == "THERMAL") {
                    devices.push({id:'THERMENERGY', name: 'heating', units: 'kWH'})
                }
                else if(ref.type == "SANITARYHOTWATER") {
                    devices.push({id:'WATERVOL', name: 'hvac', units: 'm³'})
                }
                else if(ref.type == "COOLING") {
                    devices.push({id:'TENERGYC', name: 'refrigeration', units: 'kWH'})
                }
            }
            break;
        }
    }
    
    return <Grid spacing={3} container>
        {/* <Grid item container xs={12} md={7}>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('graphs')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <RangedGrapics minLimit={minLimit} device={value} selectable={false} labels='KWh' dateStart={minLimit} route='consumption/ranged'/>
            </Grid>
        </Grid> */}
        <Grid xs={12} container spacing={2}>
            <Grid item xs={6} className={classes.verticalSpacer}>
                <Typography align="right" className={classes.muiTypographyH6Important}>{t('my_consumption_of')}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Select
                    labelId="type-select-helper-label"
                    id="type-select-helper"
                    defaultValue={param}
                    onChange={handleSelectionChange}
                    className={classes.muiTypographyH6Important}
                    >
                        {devices.map((option) => {
                            return <MenuItem
                                value={option.id}
                                units={option.units}
                            >
                                {t(option.name)}
                            </MenuItem>
                        })}
                </Select>
            </Grid>
        </Grid>
        <Grid classes={{root:classes.mycurrentconsumption}} item xs={12} md={5}><MiConsumoResume device={value} param={param} units={units}/></Grid>
        <Grid item xs={12} md={7} className={classes.vCentered}>
          <Forecast forecast={forecast} tempClass='perfect'/>
        </Grid>
        <Grid item xs={12} className={classes.rangeSpacer}>
            <SubTabbed deviceid={value} param={param} units={units}/>
        </Grid>
    </Grid>
}