import { Button, ButtonGroup, Grid, makeStyles, MenuItem, MenuList, Popper } from '@material-ui/core'
import React, { useEffect } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    responsiveText: {
        "& span": {
           fontSize: 'calc(0.6em + 0.4vw)' 
        }
        
    },
}));
export default function LocationSelector(props, userInfo) {
    const classes = useStyles();
    const options = userInfo.access_policy.locations.map((location) => {
        return {
            name: location.name,
            id: location.id,
            devices: location.reference_devices.map((device) => {
                return {
                    type: device.type,
                    id: device.device.id
                }
            })
        }
    });
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    useEffect(() => {
        props.onChange(options[0])
    }, [])
    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };
    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        props.onChange(options[index])
    };

    const handleToggle = (event) => {
        if (anchorEl === null) setAnchorEl(event.currentTarget);
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorEl && anchorEl.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    return <Grid container item justify='flex-end' alignItems='flex-end' xs={5} sm={4} style={{paddingRight: '0.4em'}} className={classes.responsiveText}>
        <ButtonGroup variant="contained" color="primary" ref={anchorEl} aria-label="split button">
            <Button onClick={handleClick}>{options[selectedIndex].name}</Button>
            <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper open={open}  anchorEl={anchorEl} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" className={classes.root}>
                                {options.map((option, index) => {
                                    return <MenuItem
                                        key={option.name}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {/* {option.name + ' [' + option.devices.find((dev) => { return dev.type === 'THERMAL' }).id + ']'} */}
                                        {option.name}
                                    </MenuItem>
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </Grid>
}