import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useCSS = makeStyles({

    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'white'
    },
    title_big: {
        fontSize: '2.5rem !important',
        lineHeight: '2.6rem !important'
    },
    title_small: {
        fontSize: '1.1rem !important',
        lineHeight: '1.2rem !important'
    },
    subtitle: {
        fontSize: '1rem !important',
        textTransform: 'uppercase',
        textAlign: 'center',
        lineHeight: '1rem !important',
        color: 'white'
    },
    subtitle2: {
        fontSize: '0.625rem !important',
        textTransform: 'uppercase',
        textAlign: 'center',
        lineHeight: '0.7rem !important',
        margin: '1rem'
    },

    rounded: {
        borderRadius: '50%',
        background: '#4b619c',
        width: '5rem',
        minHeight: '5em',
        height: '5em',
    },

    large: {
        height: '8rem',
        minHeight: '8rem',
        width: '8rem',
        minWidth: '8rem',
        alignContent: 'center'
    },
    small: {
        alignItems: 'unset',
        textAlign: 'center',
        padding: '0.3em'
    },

    ok: {
        background: "#bfdc6f !important"
    },
    notok: {
        background: "#ef727b !important"
    },
});

export default function InfoBadge(props) {
    const { value, badgeSize, units, comment} = props;

    const styles = useCSS();

    let style = '' + styles.rounded;
    let titleStyle = '' + styles.title;
    if (badgeSize === "large") {
        style += ' ' + styles.large;

        titleStyle += ' ' + styles.title_big;
    }
    else {
        style += ' ' + styles.small;
        style += ' ' + styles.ok;

        titleStyle += ' ' + styles.title_small;
    }

    return <Grid container justify='center' alignItems='center' direction='column'>
        <Grid item container xs={12} className={style} justify='center' alignItems='center' direction='column'>
            <Grid item><Typography className={titleStyle}>{value}</Typography></Grid>
            {units ? <Grid item><Typography className={styles.subtitle}>{units}</Typography></Grid> : ''}
        </Grid>
        {comment ? <Grid item xs={12} className={styles.subtitle2}>{comment}</Grid> : ''}
    </Grid>
}